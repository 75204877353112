import { Row, Col, Divider, Card } from "antd";
import * as React from 'react';
import CotisationMessage from "../form/cotisationMessage";
import Checkbox from "antd/es/checkbox";
import { useState } from "react";

export default (props) => {
    return (
        <div>
            <Row>
                <Col span={24} style={{ marginTop: 16 }} border={false}>
                    <div style={{ background: '#FFBA00', border: '#FFBA00', borderRadius: 3 }}>
                        <CotisationMessage
                            tarif={props.tarif}
                            isFloat={props.isFloat}
                            effectiveDateReducer={props.effectiveDateReducer}
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 32 }} >
                <Divider orientation="left">Données personnelles</Divider>
                <Col span={24}>
                    <Checkbox onChange={props.toggleRead}><span style={{ color: 'rgba(255,0,0,1)' }}>* </span>
                        <b>J'ai lu la rubrique </b> <a style={{ display: "table-cell" }} target="_blank"
                            href="https://www.altima-assurances.fr/altima/fr/donn%C3%A9es-personnelles?p=btob"> (ici) </a> <b> sur le traitement des données personnelles
                                et j'accepte que mes données personnelles soient traitées par Altima et ses partenaires.</b>
                    </Checkbox>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 32 }} >
                <Col span={24}>
                    J'accepte de recevoir des informations et des offres commerciales, de la part d'Altima
                    et ses partenaires, ou de répondre à des enquêtes de satisfaction. Je suis informé que mon consentement peut être retiré à tout moment.
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 16 }} >
                <Col span={24}>
                    <Checkbox onChange={props.toggleMail}><b>Par voie électronique ou courrier</b></Checkbox>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 16 }} type={'flex'} justify={'space-between'}>
                <Col span={24}>
                    <Checkbox onChange={props.togglePhone}><b>Par téléphone</b></Checkbox>
                </Col>
            </Row>
        </div>
    );
}