import React from 'react';
import {Col} from "antd";
import {LogoAltimaIcon} from "../../assets/icons";


const Logo = ({lessor}) => {

    return (<>

        <Col style={{textAlign: 'center'}}>
          {
            window.devicePixelRatio > 1 ?
              <LogoAltimaIcon className="logoAltima-retina" />
              :
              <img id="logo" src="/logo_altima_maif_215.png" alt="Logo altima" style={{width: "100%"}}/>
          }
        </Col>
        {lessor  && lessor.information && lessor.information.urlLogo &&
        <>
            <Col span={2} style={{textAlign: 'center', alignSelf: 'center'}}>
                <img id="logo" src="/Separateur-logo-partenaire.png"
                     style={{
                         maxWidth: '12px',
                         maxHeight: '75px',
                         width: '100%',
                         height: 'auto'
                     }}
                     alt="logo courbe"/>
            </Col>
            <Col span={10} style={{textAlign: 'center', alignSelf: 'center'}}>
                <img id="logo" src={lessor.information.urlLogo}
                     style={{
                         maxWidth: '130px',
                         maxHeight: '130px',
                         width: '60%',
                         height: 'auto'
                     }}
                     alt="Logo Bailleur"/>
            </Col>
        </>}
    </>)

};


export default Logo;