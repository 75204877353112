import { Card, Col, Icon, Modal } from "antd";
import React from "react";


const PointAConnaitre = ({ franchiseGenerale, franchiseBDV, capitalObjetValeur }) => {

    const onInfo = () => {
        Modal.info({
            width: 600,
            title: <span style={{ color: '#FFBA00' }}>Information</span>,
            content: (
                <div>
                    <p><b>CE QUE NOUS NE GARANTISSONS PAS :</b></p>
                    <p>• les terrains, cultures et plantations,</p>
                    <p>• les biens détenus à usage professionnel,</p>
                    <p>• les piscines et leurs abris,</p>
                    <p>• les serres et leur contenu,</p>
                    <p>• les espèces, billets de banque, pièces ou lingots de métaux précieux, titres et autres valeurs mobilières,</p>
                    <p>• les bijoux et objets précieux dans les dépendances et dans les garages,</p>
                    <p>• les objets de valeur (les fourrures, les tableaux, dessins d'art, gravures, sculptures, autres objets entièrement exécutés de la main de l'artiste en exemplaire unique, en série limitée ou numérotée, tapis, tapisseries, armes, livres rares, les collections d'objets rares, tout autre objet d'une valeur supérieure à 5 000 €),</p>
                    <p>• les exclusions communes à toutes les garanties précisées dans les conditions générales,  dont pour la responsabilité civile : les conséquences pécuniaires de la responsabilité civile que vous pouvez encourir pour les dommages (corporels, matériels et immatériels consécutifs) résultant d'un évènement accidentel causés à autrui au cours de votre vie privée, du fait des chiens de catégorie 1 et 2 visés à l'article L211-12 du Code rural dont vous répondez.</p>
                </div>
            )
        });
    };


    return (
        <Col xs={24} lg={{ span: 10, offset: 1 }}>
            <Card bordered={false}
                headStyle={{ background: '#FFBA00' }} title={<span style={{ color: 'white' }}>
                    Les points à connaître</span>}
                extra={<span onClick={onInfo}>
                    <Icon type="question-circle" style={{ fontSize: 20, color: 'white' }} />
                </span>}>
                <ul>

                    <li>La franchise générale est de {franchiseGenerale} € et la franchise
                        bris de vitre est de {franchiseBDV} €.</li>


                    <li>Les objets précieux contenus dans le logement loué sont assurés
                        jusqu'à {Intl.NumberFormat().format(capitalObjetValeur)}&#8239;€.</li>


                </ul>
            </Card>
        </Col>
    )

};

export default PointAConnaitre