import { Row, Col, Divider, Input, Descriptions } from "antd";
import Collapse from "antd/lib/collapse/Collapse";
import * as React from 'react';
import FormItem from "antd/es/form/FormItem";
import { useSelector } from "react-redux";
import moment from "moment";

export default (props) => {
    const personReducer = useSelector(state => state.personReducer);
    const countriesReducer = useSelector(state => state.countriesReducer);
    const professionsReducer = useSelector(state => state.professionsReducer);

    let completeBirthCountry;
    let completeNationality;
    let completeProfession;

    if (personReducer.person !== "") {
        completeBirthCountry = countriesReducer.countries.filter(
            country => country.alpha2.toLowerCase() === personReducer.person.birthCountry.toLowerCase()
        );
        completeNationality = countriesReducer.countries.filter(
            country => country.alpha2.toLowerCase() === personReducer.person.nationality.toLowerCase()
        );
        completeProfession = professionsReducer.professions.filter(
            profession => profession.id.toLowerCase() === personReducer.person.profession.toLowerCase()
        )
    }

    return (
        <div>
            <Row gutter={16} style={{ marginTop: 32 }}>
                <Descriptions>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Civilité : </label>
                                {personReducer.person ? personReducer.person.title === '01' ? 'M.' : 'Mme.' : undefined}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Prénom : </label>
                                {personReducer.person ? personReducer.person.firstname : undefined}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Nom : </label>
                                {personReducer.person ? personReducer.person.lastname : undefined}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Date de naissance : </label>
                                {personReducer.person ? moment(personReducer.person.birthdate, 'YYYYMMDD').format('DD/MM/YYYY') : undefined}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Pays de naissance : </label>
                                {personReducer.person ? completeBirthCountry[0].nameFr : ''}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Ville de naissance : </label>
                                {personReducer.person ? personReducer.person.birthCity + ' (' + personReducer.person.birthZipCode + ')' : ''}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Nationnalité : </label>
                                {personReducer.person ? completeNationality[0].name : ''}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Activité : </label>
                                {"Sans activité professionnelle au domicile"}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Profession : </label>
                                {personReducer.person ? completeProfession[0].label : ''}</span>
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            </Row>
            <Divider style={{ background: '#FFBA00' }} />
            <Row gutter={16}>
                <Descriptions>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>E-mail : </label>
                                {personReducer.person ? personReducer.person.mail : undefined}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Téléphone : </label>
                                {personReducer.person ? personReducer.person.phone : undefined}</span>
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            </Row>
        </div>
    );
}