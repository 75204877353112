
import { GET_LESSOR_ASK, GET_LESSOR_FULLFILED, GET_LESSOR_ERROR} from './type';

const defaultState = { lessor: undefined, errors:[], isPending: false }

/**
* Reducer pour l'objet lessor dans le store (infos bailleur)
* @param {*} state 
* @param {*} action 
*/
export const lessorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_LESSOR_ASK:
      return {
        ...state,
        isPending: true
      }
    case GET_LESSOR_FULLFILED:
      action.payload
      return {
        ...state,
        lessor: action.payload,
        errors: [],
        isPending: false
      }
    case GET_LESSOR_ERROR:
      action.payload
      return {
        ...state,
        error: action.payload,
        isPending: false
      }
    default:
      return state;
  }
}
