import { GET_INFO_LOIHAMON, SET_INFO_LOIHAMON, SET_INFO_LOIHAMON_ERROR, GET_INFO_LOIHAMON_PENDING } from '../reducers/type';

export const getInfoLoiHamon = (isPending) => ({
  type: GET_INFO_LOIHAMON,
  payload: isPending
});

export const getIsPendingHamon = (isPending) => ({
  type: GET_INFO_LOIHAMON_PENDING,
  payload: isPending
});

export const setInfoLoiHamon = (infoLoiHamon) => ({
  type: SET_INFO_LOIHAMON,
  payload: infoLoiHamon
});

export const setEffectiveDateError = (error) => ({
  type: SET_INFO_LOIHAMON_ERROR,
  payload: error
});






