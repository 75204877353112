import { Col, Card, Spin, Form } from 'antd';
import Collapse from "antd/lib/collapse/Collapse";
import * as React from 'react';


const Panel = Collapse.Panel


export default ({ capital }) => {



    if (capital === undefined) {
        return (<Col xs={24} lg={10}>
            <Spin />
        </Col>)
    }


    return (

        <Col xs={24} lg={10}>
            <Card bordered={false} headStyle={{ background: '#FFBA00' }} title={<span style={{ color: 'white' }}>Les points forts du contrat</span>} >
                <div className="view-desktop">
                    <ul>
                        <li><b>Aucun frais de mensualisation</b></li>
                        <li><b>Aucun frais de souscription</b></li>
                        <li><b>Protection de tous les occupants du logement</b> en responsabilité civile</li>
                        <li><b>Couverture optimale :</b> Le contenu de votre logement et de ses dépendances est
                            assuré{capital.capitalObjetUsuel !== undefined ? <span> jusqu'à {Intl.NumberFormat().format(capital.capitalObjetUsuel)} €</span> : ""}. Y compris vos appareils électriques en cas de foudre !</li>
                        <li><b>Des services d'assistance gratuits :</b>
                            <ul>
                                <li>Perte de clé : envoi d'un serrurier,</li>
                                <li>Fuite : envoi d'un plombier,</li>
                                <li>Hébergement provisoire, transfert des enfants chez un proche, transfert
                                    et garde d'animaux... en cas de sinistre,</li>
                                <li>Assistance voyage monde entier,</li>
                                <li>Prise en charge des enfants scolarisés, aide à domicile, école à
                                    domicile en cas d'immobilisation à domicile pour raison de santé.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="view-mobile" style={{ display: 'none' }}>
                    <Collapse accordion>
                        <Panel header="Les avantages" key="1">
                            <ul>
                                <li ><b>Aucun frais de mensualisation</b></li>
                                <li><b>Aucun frais de souscription</b></li>
                                <li><b>Protection de tous les occupants du logement</b> en responsabilité civile</li>
                                <li><b>Couverture optimale :</b> Le contenu de votre logement et de ses dépendances est
                                    assuré{capital.capitalObjetUsuel !== undefined ? <span> jusqu'à {Intl.NumberFormat().format(capital.capitalObjetUsuel)} €</span> : ""}. Y compris vos appareils électriques en cas de foudre !</li>
                                <li><b>Des services d'assistance gratuits :</b>
                                    <ul>
                                        <li>Perte de clé : envoi d'un serrurier,</li>
                                        <li>Fuite : envoi d'un plombier,</li>
                                        <li>Hébergement provisoire, transfert des enfants chez un proche, transfert
                                            et garde d'animaux... en cas de sinistre,</li>
                                        <li>Assistance voyage monde entier,</li>
                                        <li>Prise en charge des enfants scolarisés, aide à domicile, école à
                                            domicile en cas d'immobilisation à domicile pour raison de santé.</li>
                                    </ul>
                                </li>
                            </ul>
                        </Panel>
                    </Collapse>
                </div>
            </Card>
        </Col>);
}