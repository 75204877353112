import { Alert, Card, Col, Divider, Row } from 'antd';
import React from 'react';
import Step from '../components/step';

export default () => {

    return (
        <div>
            <Step step={7} />
            <Row style={{ marginTop: '16px' }}>
                <div>
                    <Row type={'flex'} justify={'center'}>
                        <Col span={24}>
                            <div className='view-desktop'>
                                <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Souscription</h2></Divider>
                            </div>
                            <div className="view-mobile" style={{ display: 'none' }}>
                                <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Souscription</h3></Divider>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <Card style={{ marginTop: 32 }} bordered={false}>
                            <Row gutter={16} type="flex" justify="center" style={{ marginTop: 32 }}>
                                <Alert
                                    message="Bienvenue chez Altima."
                                    description="Votre souscription est validée."
                                    type="success"
                                    showIcon
                                />
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: 32 }}>
                                    <h4>La souscription de votre assurance auprès d'Altima est maintenant validée. Merci de votre confiance.</h4>
                                    <p>Vous allez recevoir un mail avec en pièces jointes :</p>
                                    <p>- Vos Conditions Particulières</p>
                                    <p>- Les Conditions Générales</p>
                                    <p>- Votre attestation d'assurance à remettre au bailleur
                                    et un mandat de prélèvement à signer et à télécharger une fois signé (pour le prélèvement des échéances suivantes).
                                            </p>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            </Row>

        </div>
    );
};