import { Button, Card, Col, Form, Row, Spin, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link, useHistory } from "react-router-dom";
import Step from '../components/step';
import { getTarif } from "../service/tarifService";
import ResumeContractor from '../components/resume/resumeContractor'
import ResumeHousing from '../components/resume/resumeHousing'
import ResumeSubscription from '../components/resume/resumeSubscription'
import { getHousingWrong } from "../actions/housing";
import { resetProposal } from "../service/devisService";
import { sendConsent } from "../service/personService";
import WebCallBack from "../components/webCallBack/webCallBack"

const FormItem = Form.Item;

export default () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [readRGPD, isReadRGPD] = useState(false);
    const [mailConsent, isMailConsent] = useState(false);
    const [phoneConsent, isPhoneConsent] = useState(false);
    const [isPendingTarif, setIsPendingTarif] = useState(true);
    const [tarif, setTarif] = useState("");
    const [isFloat, setIsFloat] = useState(false);

    const housingReducer = useSelector(state => state.housingReducer);
    const devisReducer = useSelector(state => state.devisReducer);
    const effectiveDateReducer = useSelector(state => state.effectiveDateReducer);

    useEffect(() => {
        if (housingReducer.house !== undefined) {
            getTarif(housingReducer.house.housingCode).then(response => {
                setTarif(response[0].montantsAnnuel.mtTTC);
                let tarifFloat = response[0].montantsAnnuel.mtTTC
                if (tarifFloat.toString().includes(".")) {
                    setIsFloat(true)
                } else {
                    setIsFloat(false)
                }
                setIsPendingTarif(false);
            }).catch(error => {
                setIsPendingTarif(false);
                alertError(error);
            })
        }
    }, []);

    const toggleRead = () => {
        isReadRGPD(!readRGPD);
    };

    const toggleMailConsent = () => {
        isMailConsent(!mailConsent)
    };

    const togglePhoneConsent = () => {
        isPhoneConsent(!phoneConsent)
    };

    function handleSubmit(e) {
        e.preventDefault();
        //TODO
    }

    function alertError(e) {
        Modal.error({
            content: "Erreur lors de la récupération du tarif",
            onOk() {
                dispatch(getHousingWrong());
                //setBadHouse(true)
            }
        });
    }

    if (!housingReducer.isAuthenticated) {
        return (<Redirect to='/'></Redirect>)
    }

    if (devisReducer.isPending) {
        return (
            <div>
                <Step step={3} />
                <Row style={{ marginTop: '16px' }}>
                    <div>
                        <Spin spinning={true}>
                        </Spin>
                    </div>
                </Row>
            </div>
        )
    }


    const submitConsent = () => {
        sendConsent(devisReducer.idRefPers, mailConsent, phoneConsent);
    };

    const backToIdentite = () => {
        dispatch(resetProposal());
        history.push('/identite');
    };

    const backToDevis = () => {
        dispatch(resetProposal());
        history.push('/devis');
    };

    return (
        <div>
            <Step step={3} />
            <Row style={{ marginTop: '16px' }}>
                <Row type="flex" justify="end">
                    <Col span={2} style={{ marginRight: -6, marginTop: 32 }}>
                        <WebCallBack />
                    </Col>
                </Row>
                <div>
                    <Spin spinning={isPendingTarif}>
                        <Form onSubmit={e => handleSubmit(e)}>
                            <div className='view-desktop'>
                                <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Je vérifie mes
                                informations</h2></Divider>
                            </div>
                            <div className="view-mobile" style={{ display: 'none' }}>
                                <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Je vérifie mes
                                informations</h3></Divider>
                            </div>
                            <h3>Voici les caractéristiques de votre proposition qui serviront de base à l'établissement de
                            votre contrat :</h3>
                            <Row>
                                <Row>
                                    <Col span={24}>
                                        <Card
                                            bordered={false}
                                            title="Souscripteur"
                                            extra={
                                                <FormItem>
                                                    <Button type="primary" onClick={() => backToIdentite()}>Modifier</Button>

                                                </FormItem>}>
                                            <ResumeContractor />
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Card
                                            bordered={false}
                                            title="Logement"
                                            style={{ marginTop: 32 }}
                                        >
                                            <ResumeHousing />
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Card
                                            id="SummaryInvoiceExtendView"
                                            bordered={false}
                                            title="Prime d'assurance"
                                            style={{ marginTop: 32 }}
                                        >
                                            <ResumeSubscription
                                                toggleRead={toggleRead}
                                                toggleMail={toggleMailConsent}
                                                togglePhone={togglePhoneConsent}
                                                tarif={tarif}
                                                effectiveDateReducer={effectiveDateReducer}
                                                isFloat={isFloat}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Row>
                            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 16 }}>
                                <Col xs={24} lg={4}>
                                    <FormItem>
                                        <Button
                                            onClick={() => backToDevis()}
                                            type="secondary"
                                            style={{ width: '100%' }}
                                        >
                                            Précédent
                                        </Button>
                                    </FormItem>
                                </Col>
                                <Col xs={24} lg={4}>
                                    <FormItem>
                                        <Link to='/conseil'>
                                            <Button
                                                type="primary"
                                                style={{ width: '100%' }}
                                                disabled={!readRGPD}
                                                onClick={() => submitConsent()}
                                            >
                                                Suivant
                                            </Button>
                                        </Link>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div>
            </Row>
        </div>
    );
}

