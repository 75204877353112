import React from "react";
import { Col } from "antd";

function RetractationInfo() {
  return (
    <Col span={24} style={{ color: '#a6a0a0' }}>
      Conformément à l'article L.112-2-1 du Code des assurances, vous disposez
      d'un délai de
      14 jours
      calendaires révolus, à compter de la date de souscription, pour vous
      rétracter, sans
      avoir
      à justifier de motif, ni à supporter de pénalité. Cette rétractation
      devra être envoyée
      par courrier recommandé avec avis de réception à l'adresse suivante :
      ALTIMA, CS 88319, 79043 Niort Cedex 9.
      Ce droit ne peut plus vous être ouvert, si vous demandez que l'exécution
      de votre
      contrat
      d'assurance commence avant la fin de ce délai de 14 jours.
    </Col>
  );
}

export default RetractationInfo;