import { GET_EFFECTIVEDATE, SET_EFFECTIVEDATE, SET_EFFECTIVEDATE_ERROR, SET_PAYINGDATE, GET_PAYINGDATE } from '../reducers/type';

export const getEffectiveDateAsk = (isPending) => ({
  type: GET_EFFECTIVEDATE,
  payload: isPending
});

export const getPayingDateAsk = (isPending) => ({
  type: GET_PAYINGDATE,
  payload: isPending
});

export const setEffectiveDate = (oneEffectiveDate) => ({
  type: SET_EFFECTIVEDATE,
  payload: oneEffectiveDate
});

export const setPayingDate = (onePayingDate) => (
  {
    type: SET_PAYINGDATE,
    payload: onePayingDate
  });

export const setEffectiveDateError = (error) => ({
  type: SET_EFFECTIVEDATE_ERROR,
  payload: error
});






