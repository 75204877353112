import { Divider, Tabs, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHousing } from "../../service/houseService";
import { getFaq } from "../../service/contentService";

const Panel = Collapse.Panel;
const TabPane = Tabs.TabPane;

export default (props) => {

    const [faqs, setFaqs] = useState([]);
    const [endLoad, setEndLoad] = useState(false);

    const dispatch = useDispatch();
    const housingReducer = useSelector(state => state.housingReducer);

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const login = params.get('login');

    useEffect(() => {
        if (login !== null) {
            dispatch(getHousing(login))
            getFaq().then(response => {
                if (response) {
                    setFaqs(response);
                    setEndLoad(true);

                }
            });
        }
    }, [])

    if (login !== null) {
        if (housingReducer.error !== undefined) {
            return (<Redirect to='/' />)
        }
    }

    const renderAnswers = (answers) => {
        return answers.map(
            (item, index) => <Panel header={item.titre} key={`${index}`}>
                <div dangerouslySetInnerHTML={{ __html: item.description.value }} />
            </Panel>
        )
    };

    return (
        <div>
            <div className='view-desktop'>
                <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Questions Fréquentes</h2></Divider>
            </div>
            <div className="view-mobile" style={{ display: 'none' }}>
                <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Questions Fréquentes</h3></Divider>
            </div>
            <Tabs defaultActiveKey="0">
                {
                    endLoad &&
                    faqs.map((faq, index) => {
                        if (index === 0 || faq.rubrique != faqs[index - 1].rubrique) {
                            return (
                                <TabPane tab={faq.rubrique} key={index}>
                                    <Collapse accordion>
                                        {
                                            renderAnswers(faqs.filter((faq2) => faq.rubrique === faq2.rubrique))
                                        }
                                    </Collapse>
                                </TabPane>
                            )
                        }
                    })
                }
            </Tabs>
        </div>
    );
};