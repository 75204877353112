import instance from './services'

import {getLessorAsk,getLessorFullfiled,getLessorError} from '../actions/lessor'

export function getLessor() {
    return dispatch => {
      const url = encodeURI("/api/v1");
      dispatch(getLessorAsk());
      instance.get(url)
        .then(res => res.data)
        .then(lessor => {
          dispatch(getLessorFullfiled(lessor));
          return lessor;
        })
        .catch(error => dispatch(getLessorError(error)));
    }
}