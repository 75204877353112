export const calculateFirstDebit = (momentDate) => {
  const dateNow = momentDate.clone()
  let datePrelevement;
  if (dateNow.date() >= 1 && dateNow.date() <= 5) {
    datePrelevement = dateNow.add(2, "M");
    datePrelevement.set('date', 15);
  } else if (dateNow.date() >= 6 && dateNow.date() <= 26) {
    datePrelevement = dateNow.add(3, "M");
    datePrelevement.set('date', 5);
  } else {
    datePrelevement = dateNow.add(3, "M");
    datePrelevement.set('date', 15);
  }
  return datePrelevement.format('DD/MM/YYYY')
}