import React, { useState, useEffect } from "react";
import { getConfig } from "../service/configService";
import { Row, Col, Card, Button } from "antd";

import Collapse from "antd/lib/collapse/Collapse";

const Panel = Collapse.Panel;

const ListDocuments = () => {

    const [linkToDoc, setLinkToDoc] = useState("");

    useEffect(() => {
        getConfig().then(res =>
            setLinkToDoc(res.DOC_LOC_URL));
    }, []);

    const openGaranties = () => {
        window.open(linkToDoc + "tableau_garanties.pdf",
            "_blank");
    };

    const openIpid = () => {
        window.open(linkToDoc + "IPID.pdf",
            "_blank");
    };

    const openFicheInfo = () => {
        window.open(linkToDoc + "fiche_information.pdf",
            "_blank");
    };

    const openCG = () => {
        window.open(linkToDoc + "Conditions_Generales.pdf",
            "_blank");
    };

    return (

        <Col xs={24} lg={10}>
            <Card bordered={false} headStyle={{ background: "#FFBA00" }} title={<span style={{ color: "white", whiteSpace: "normal" }}>
                Pour tout savoir : les documents précontractuels et contractuels</span>}>


                <Row>
                    <Col span={24}>
                        <Collapse bordered={false}>
                            <Panel header="Document d'information standardisé" key="1">
                                <Row gutter={16} type="flex" justify="space-between">
                                    <Col xs={24} lg={20}>
                                        Document synthétique de l'offre.
                                    </Col>
                                    <Col xs={24} lg={4} style={{ textAlign: "center" }}>
                                        <Button
                                            style={{ textAlign: "center" }}
                                            type="primary"
                                            onClick={openIpid}
                                        >
                                            Voir
                                        </Button>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel header="Tableau des garanties" key="2">
                                <Row gutter={16} type="flex" justify="end">
                                    <Col xs={24} lg={20}>
                                        Document d'information sur les garanties et leurs limites.
                                    </Col>
                                    <Col xs={24} lg={4} style={{ textAlign: "center" }}>
                                        <Button
                                            style={{ textAlign: "center" }}
                                            type="primary"
                                            onClick={openGaranties}
                                        >
                                            Voir
                                        </Button>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel header="La fiche d'information" key="3">
                                <Row gutter={16} type="flex" justify="space-between">
                                    <Col xs={24} lg={20}>
                                        Document d'information et de conseil sur le coût, les garanties et les modalités
                                        d'adhésion du contrat.
                                    </Col>
                                    <Col xs={24} lg={4} style={{ textAlign: "center" }}>
                                        <Button
                                            style={{ textAlign: "center" }}
                                            type="primary"
                                            onClick={openFicheInfo}
                                        >
                                            Voir
                                        </Button>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel header="Les conditions générales" key="4">
                                <Row gutter={16} type="flex" justify="end">
                                    <Col xs={24} lg={20}>
                                        Pour tout connaître de l'offre.
                                    </Col>
                                    <Col xs={24} lg={4} style={{ textAlign: "center" }}>
                                        <Button
                                            style={{ textAlign: "center" }}
                                            type="primary"
                                            onClick={openCG}
                                        >
                                            Voir
                                        </Button>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

export default ListDocuments;
