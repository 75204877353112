import instance from './services'

import {getCapitauxAsk, setCapitaux, setCapitauxError} from '../actions/capital'

export function getCapital (nbPieces) {
    return dispatch => {
        const url = encodeURI("/api/v1/capitaux/" + nbPieces);
        dispatch(getCapitauxAsk(true));
        instance.get(url)
            .then(res => res.data)
            .then(oneCapital => {
                dispatch(setCapitaux(oneCapital));
                dispatch(getCapitauxAsk(false));
            })
            .catch(error => dispatch(setCapitauxError(error)))
    }
}