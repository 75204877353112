import { SET_PERSON, SAVE_PERSON} from '../reducers/type';


export const setPersonFullfiled = (person) => ({
  type: SET_PERSON,
  payload : person
});



export const savePerson = () => ({
  type: SAVE_PERSON,
  payload : undefined
});





