import { GET_CAPITAUX, SET_CAPITAUX, SET_CAPITAUX_ERROR } from '../reducers/type';

export const getCapitauxAsk = (isPending) => ({
  type: GET_CAPITAUX,
  payload: isPending
});

export const setCapitaux = (oneCapital) => ({
  type: SET_CAPITAUX,
  payload : oneCapital
});

export const setCapitauxError = (error) => ({
  type: SET_CAPITAUX_ERROR,
  payload : error
});






