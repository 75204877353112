import React from "react"
import { string } from "prop-types";
import { Button, Col, Form } from "antd";
import { Link } from "react-router-dom";

const FormItem = Form.Item;

function PreviousButton({ url }) {
  return (
    <Col xs={24} lg={4}>
      <FormItem>
        <Link to={url}>
          <Button type="secondary" style={{ width: '100%' }}>
            Précédent
          </Button>
        </Link>
      </FormItem>
    </Col>
  )
}

PreviousButton.propTypes = {
  url: string.isRequired
}

export default PreviousButton