import { Col, Input, Row, Descriptions } from 'antd';
import * as React from 'react';
import FormItem from "antd/es/form/FormItem";

export default (props) => {
    return (
        <div>
            <Row gutter={16} className="cotisationMessage">
                <Descriptions size={"small"} className={""} column={{ xs: 1, sm: 1, md: 4, lg: 5 }} style={{ paddingTop: "8px" }}>
                    <Descriptions.Item>
                        <div className="resume-cotisation">
                            <label className={"resume-payment"}>Date d'effet du contrat : </label>
                            {
                                props.effectiveDateReducer.effectiveDate !== "" &&
                                props.effectiveDateReducer.effectiveDate
                            }
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className="resume-cotisation">
                            <label className={"resume-payment"}>Date de premier prélévement : </label>
                            {
                                props.effectiveDateReducer.payingDate !== "" &&
                                props.effectiveDateReducer.payingDate
                            }
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className="resume-cotisation">
                            <label className={"resume-payment"}>Modalité de paiement : </label>
                            {"mensuel"}
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        {
                            props.tarif !== "" &&
                            <div className="resume-cotisation">
                                <label className={"resume-payment"}>Prime mensuelle : </label>
                                {
                                    props.isFloat === false &&
                                    (props.tarif / 12) + ' €'
                                }
                                {
                                    props.isFloat === true &&
                                    (props.tarif / 12).toFixed(2) + ' €'
                                }
                            </div>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item>
                        {
                            props.tarif !== "" &&
                            <div className="resume-cotisation">
                                <label className={"resume-payment"}>Prime annuelle : </label>
                                {
                                    props.isFloat === false &&
                                    props.tarif + ' €'
                                }
                                {
                                    props.isFloat === true &&
                                    props.tarif.toFixed(2) + ' €'
                                }
                            </div>
                        }
                    </Descriptions.Item>
                </Descriptions>
            </Row >
        </div>
    );
}