import { Row } from 'antd';
import React from 'react';

export default () => {

    return (
        <div>
            <Row style={{ marginTop: '16px' }}>
                <div>
                    <Row gutter={16} type="flex" justify="center" style={{ marginTop: 32 }}>
                        <div>
                            <div className={"erreur-404"}>
                                <div className={"image-upload"}></div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <span className={"text-error"}>Merci, nous avons bien reçu votre document</span>
                            </div>
                        </div>
                    </Row>
                </div>
            </Row>
        </div >
    );
};