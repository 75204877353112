import instance from './services'

import { getHousingAsk, getHousingError, getHousingFullfiled } from '../actions/housing'

export function getHousing(codeLogement, isSecret) {
    return dispatch => {
        let url;
        if (isSecret) {
            url = encodeURI("/api/v1/logement/secret/" + codeLogement);
        } else {
            url = encodeURI("/api/v1/logement/" + btoa(codeLogement)); 
        }
        
        dispatch(getHousingAsk());
        instance.get(url)
            .then(res => res.data)
            .then(oneHouse => {
                dispatch(getHousingFullfiled(oneHouse));
            })
            .catch(error => dispatch(getHousingError(error)));
    }
}