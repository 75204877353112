import * as React from "react";
import { Input, Form } from "antd";
import Icon from "antd/lib/icon";
import { useState, useEffect } from "react";

const FormItem = Form.Item;

// !Only fr BIC
// Todo: add other countries BIC format & merge in Iban component
const Bic = (props) => {
    const [bic, setBic] = useState("");
    const [formattedBic, setFormattedBic] = useState("");

    const { getFieldDecorator } = props.form;

    const format = (bic) => {
        if (!bic) {
            return "";
        }

        const cleanBic = bic
            .replace(/\s\s+/g, " ")
            .replace(/[^0-9a-zA-Z]/gi, "")
            .toUpperCase();

        const parts = [];

        if (cleanBic.length > 0) {
            parts.push(cleanBic.substring(0, 4));
        }

        if (cleanBic.length > 4) {
            parts.push(cleanBic.substring(4, 6));
        }

        if (cleanBic.length > 6) {
            parts.push(cleanBic.substring(6, 8));
        }

        if (cleanBic.length > 8) {
            parts.push(cleanBic.substring(8, 11));
        }

        return parts.join(" ");
    };

    useEffect(() => {
        setBic(props.currentBic);
        setFormattedBic(format(props.currentBic));
    }, [props.currentBic]);

    return <FormItem label="BIC" hasFeedback={true} required>
        {
            <Input
                className="disabledInput"
                prefix={<Icon type="idcard" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="text"
                placeholder="BAAE FR PP CCT"
                defaultValue={formattedBic}
                value={formattedBic} />
        }
    </FormItem>;
};

export default Bic;