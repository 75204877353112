export const GET_HOUSING_ASK = "GET_HOUSING_ASK";
export const GET_HOUSING_FULLFILED = "GET_HOUSING_FULLFILED";
export const GET_HOUSING_ERROR = "GET_HOUSING_ERROR";
export const GET_HOUSING_WRONG = "GET_HOUSING_WRONG";

export const GET_LESSOR_ASK = "GET_LESSOR_ASK";
export const GET_LESSOR_FULLFILED = "GET_LESSOR_FULLFILED";
export const GET_LESSOR_ERROR = "GET_LESSOR_ERROR";

export const GET_COUNTRIES_FULLFILED = "GET_COUNTRIES_FULLFILED";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const GET_PROFESSIONS_FULLFILED = "GET_PROFESSIONS_FULLFILED";
export const GET_PROFESSIONS_ERROR = "GET_PROFESSIONS_ERROR";

export const SET_PERSON = "SET_PERSON";
export const SAVE_PERSON = "SAVE_PERSON";
export const SAVE_PERSON_FULLFILED = "SAVE_PERSON_FULLFILED";
export const SAVE_PERSON_ERROR = "SAVE_PERSON_ERROR";

export const GET_CAPITAUX = "GET_CAPITAUX";
export const GET_CAPITAUX_PENDING = "GET_CAPITAUX_PENDING";
export const SET_CAPITAUX = "SET_CAPITAUX";
export const SET_CAPITAUX_ERROR = "SET_CAPITAUX_ERROR";

export const GET_INFO_LOIHAMON = "GET_INFO_LOIHAMON";
export const GET_INFO_LOIHAMON_PENDING = "GET_INFO_LOIHAMON_PENDING";
export const SET_INFO_LOIHAMON = "SET_INFO_LOIHAMON";
export const SET_INFO_LOIHAMON_ERROR = "SET_INFO_LOIHAMON_ERROR";

export const STORE_DEVIS_INFORMATIONS = "STORE_DEVIS_INFORMATIONS";
export const SAVE_DEVIS_PENDING = "SAVE_DEVIS_PENDING";
export const SAVE_DEVIS_FULLFILED = "SAVE_DEVIS_FULLFILED";
export const SAVE_DEVIS_ERROR = "SET_DEVIS_ERROR";
export const RESET_DEVIS = "RESET_DEVIS";
export const SET_EXTERNAL_REF = "SET_EXTERNAL_REF";

export const GET_EFFECTIVEDATE = "GET_EFFECTIVEDATE";
export const GET_PAYINGDATE = "GET_PAYINGDATE";
export const GET_EFFECTIVEDATE_PENDING = "GET_EFFECTIVEDATE_PENDING";
export const SET_EFFECTIVEDATE = "SET_EFFECTIVEDATE";
export const SET_PAYINGDATE = "SET_PAYINGDATE";
export const SET_EFFECTIVEDATE_ERROR = "SET_EFFECTIVEDATE_ERROR";