import {
  GET_COUNTRIES_FULLFILED,
  GET_COUNTRIES_ERROR,
  GET_PROFESSIONS_FULLFILED,
  GET_PROFESSIONS_ERROR
} from '../reducers/type';


export const getProfessionsFullfiled = (professions) => ({
  type: GET_PROFESSIONS_FULLFILED,
  payload : professions
});

export const getProfessionsError = (error) => ({
  type: GET_PROFESSIONS_ERROR,
  payload : error
});


export const getCountriesFullfiled = (countries) => ({
  type: GET_COUNTRIES_FULLFILED,
  payload : countries
});

export const getCountriesError = (error) => ({
  type: GET_COUNTRIES_ERROR,
  payload : error
});




