import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import { Spin } from "antd";
import PointAConnaitre from "../../pointAConnaitre"
import { getFranchises } from "../../../service/franchiseService"

const FranchiseObjetsPrecieux = ({ capitaux }) => {

  const [franchise, setFranchise] = useState({ franchiseGeneral: "", franchiseBDV: "" });
  const [isPendingFranchise, setIsPendingFranchise] = useState(true);

  useEffect(() => {
    let isMounted = true;
    getFranchises().then(response => {
      if (isMounted) {
        setFranchise({
          franchiseGeneral: response.listeFranchiseGenerale[0].montant,
          franchiseBDV: response.listeFranchiseBrisDeVitre[0].montant
        });
        setIsPendingFranchise(false);
      }
      return () => {
        isMounted = false
      }
    })
      .catch(() => setIsPendingFranchise(false));
  }, [])

  if (capitaux && !isPendingFranchise) {
    return (
      <PointAConnaitre
        franchiseGenerale={franchise.franchiseGeneral}
        franchiseBDV={franchise.franchiseBDV}
        capitalObjetValeur={capitaux.capitalObjetValeur}/>
    );
  } else {
    return <Spin/>;
  }
}

FranchiseObjetsPrecieux.propTypes = {
  capitaux: object
}

export default FranchiseObjetsPrecieux