import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { housingReducer } from './reducers/housing';
import { capitauxReducer } from './reducers/capital';
import { lessorReducer } from './reducers/lessor';
import { countriesReducer } from './reducers/utils';
import { professionsReducer } from './reducers/utils';
import { personReducer } from './reducers/person';
import { devisReducer } from './reducers/devis';
import { effectiveDateReducer } from './reducers/effectiveDate';
import { infoLoiHamonReducer } from './reducers/infoLoiHamon'

export const store = combineReducers({
  housingReducer,
  lessorReducer,
  countriesReducer,
  professionsReducer,
  personReducer,
  capitauxReducer,
  devisReducer,
  effectiveDateReducer,
  infoLoiHamonReducer,
  routing: routerReducer
});
