import { GET_LESSOR_ASK, GET_LESSOR_FULLFILED, GET_LESSOR_ERROR } from '../reducers/type';

export const getLessorAsk = () => ({
  type: GET_LESSOR_ASK
});

export const getLessorFullfiled = (lessor) => ({
  type: GET_LESSOR_FULLFILED,
  payload : lessor
});

export const getLessorError = (error) => ({
  type: GET_LESSOR_ERROR,
  payload : error
});




