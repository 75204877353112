import { Card, Col, Descriptions, Form, Input, Row } from "antd";
import * as React from "react";
import Divider from "antd/es/divider";
import Tarif from "../tarif";

export default ({ house, tarif }) => {

    if (house === undefined) {
        return (<> Wait</>);
    }

    return (
        <div>
            <div className='view-desktop'>
                <Row gutter={16}>
                    <Card bordered={false}>
                        <Divider orientation="left">Résumé du logement</Divider>

                        <Col span={17}>
                            <Descriptions className={"custom-col-17"}>
                                <Descriptions.Item>
                                    <div className={"resume-content"}>
                                         <span><label className={"resume"}>Référence logement : </label>{house.housingCode}</span>
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item>
                                    <div className={"resume-content"}>
                                        <span><label className={"resume"}>Type de logement : </label>
                                        {house.descriptif.categorie}</span>
                                    </div>
                                </Descriptions.Item>
                                {house.descriptif.categorie === "APPT" &&
                                    <Descriptions.Item>
                                        <div className={"resume-content"}>
                                            <span><label className={"resume"}>Etage : </label>
                                            {house.adressLogement.etage}</span>
                                        </div>
                                    </Descriptions.Item>}
                                <Descriptions.Item>
                                    <div className={"resume-content"}>
                                        <span><label className={"resume"}>Nombre de pièces : </label>
                                        {house.descriptif.typeCommercial}</span>
                                    </div>
                                </Descriptions.Item>

                                <Descriptions.Item>
                                    <div className={"resume-content"}>
                                        <span><label className={"resume"}>Surface habitable : </label>
                                        {house.descriptif.livingSurface + " m²"}</span>
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={7}>
                            <Row type="flex" justify="space-between" >
                                {
                                    tarif !== "" &&
                                    <Tarif mensuel={(tarif / 12).toFixed(0)} annuel={tarif.toFixed(0)} offset={0} lg={25} titre={"Mon estimation Tarifaire"} />
                                }
                            </Row>
                        </Col>

                        <Divider orientation="left">Adresse du logement</Divider>
                        <div className={"containerInfoHouse"}>
                            <div className="columnInfoHouse">
                                <div className={"resume-content"}>
                                    <span><label className={"resume"}>Résidence : </label>{house.adressLogement.residence}</span>

                                </div>
                                <div className={"resume-content"}>
                                    <span><label className={"resume"}>Ville : </label>{house.adressLogement.city}</span>
                                </div>
                            </div>
                            <div className="columnInfoHouse">
                                <div className={"resume-content"}>
                                    <label className={"resume"}>Adresse : </label>
                                    <div className="adresseBailleur">
                                        <span>{house.adressLogement.adresse}</span>
                                        {house.adressLogement.adresse2 !== "" && <span>{house.adressLogement.adresse2}</span>}
                                        {house.adressLogement.adresse3 !== "" && <span>{house.adressLogement.adresse3}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="columnInfoHouse">
                                <div className={"resume-content"}>
                                    <span><label className={"resume"}>Code postal : </label>{house.adressLogement.postalCode}</span>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Row>
            </div>
            <div className="view-mobile" style={{ display: "none" }}>
                <Row type="flex" justify="space-between" >
                    <Tarif mensuel={(parseInt(tarif) / 12).toFixed(0)} annuel={parseInt(tarif).toFixed(0)} offset={0} lg={18} titre={"Mon estimation Tarifaire"} />
                </Row>
                <Card bordered={false} style={{ marginTop: 16 }}>
                    <Divider orientation="left">Résumé du logement</Divider>
                    <Descriptions>
                        <Descriptions.Item>
                            <div className={"resume-content"}>
                                <label className={"resume"}>Référence logement : </label>
                                {house.housingCode}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item>
                            <div className={"resume-content"}>
                                <label className={"resume"}>Type de logement : </label>
                                {house.descriptif.categorie}
                            </div>
                        </Descriptions.Item>
                        {house.descriptif.categorie === "APPT" &&
                            <Descriptions.Item>
                                <div className={"resume-content"}>
                                    <label className={"resume"}>Etage : </label>
                                    {house.adressLogement.etage}
                                </div>
                            </Descriptions.Item>}
                        <Descriptions.Item>
                            <div className={"resume-content"}>
                                <label className={"resume"}>Nombre de pièces : </label>
                                {house.descriptif.typeCommercial}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item>
                            <div className={"resume-content"}>
                                <label className={"resume"}>Surface habitable : </label>
                                {house.descriptif.livingSurface + " m²"}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Divider orientation="left">Adresse du logement</Divider>
                    <div className={"containerInfoHouse"}>
                        <div className="columnInfoHouse">
                            <div className={"resume-content"}>
                                <span><label className={"resume"}>Résidence : </label>{house.adressLogement.residence}</span>

                            </div>
                            <div className={"resume-content"}>
                                <span><label className={"resume"}>Ville : </label>{house.adressLogement.city}</span>
                            </div>
                        </div>
                        <div className="columnInfoHouse">
                            <div className={"resume-content"}>
                                <label className={"resume"}>Adresse : </label>
                                <div className="adresseBailleur">
                                    <span>{house.adressLogement.adresse}</span>
                                    {house.adressLogement.adresse2 !== "" && <span>{house.adressLogement.adresse2}</span>}
                                    {house.adressLogement.adresse3 !== "" && <span>{house.adressLogement.adresse3}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="columnInfoHouse">
                            <div className={"resume-content"}>
                                <span><label className={"resume"}>Code postal : </label>{house.adressLogement.postalCode}</span>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div >
    );
};