import instance from './services'

import {
    getCountriesFullfiled,
    getCountriesError,
    getProfessionsFullfiled,
    getProfessionsError,
} from '../actions/utils'

export function getCountries() {
    return dispatch => {
      const url = encodeURI("/api/v1/countries");
      instance.get(url)
        .then(res => res.data)
        .then(lessor => {
          dispatch(getCountriesFullfiled(lessor));
          return lessor;
        })
        .catch(error => dispatch(getCountriesError(error)));
    }
}

export function getProfessions() {
    return dispatch => {
        const url = encodeURI("/api/v1/professions");
        instance.get(url)
            .then(res => res.data)
            .then(lessor => {
                dispatch(getProfessionsFullfiled(lessor));
                return lessor;
            })
            .catch(error => dispatch(getProfessionsError(error)));
    }
}

export function getCities(query) {
    const url = encodeURI("/api/v1/cities?query="+query);
    return instance.get(url)
        .then(res => res.data)
}