export const ibanChecksumValidation = (iban) => {
    if (iban) {
        const ibanStripped = iban.replace(/[^A-Z0-9]+/gi, '') // keep numbers and letters only
            .toUpperCase(); // calculation expects upper-case
        const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
        if (!m) {
            return false;
        } else {
            const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, (ch) => (`${ch.charCodeAt(0) - 55}`));
            // The resulting number would be to long for javascript to handle without loosing precision.
            // So the trick is to chop the string up in smaller parts.
            const matchedNumber = numbericed.match(/\d{1,7}/g);
            if (matchedNumber !== null) {
                const mod97 = matchedNumber.reduce((total, curr) => Number(`${total}` + curr) % 97, 0);
                return (mod97 === 1);
            }
            return false;
        }
    } else {
        return false;
    }
};