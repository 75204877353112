import { Alert, Card, Col, Divider, Row } from 'antd';
import React, { useEffect } from 'react';

export default () => {

    return (
        <div>
            <Row style={{ marginTop: '16px' }}>
                <div>
                    <Row gutter={16} type="flex" justify="center" style={{ marginTop: 32 }}>
                        <Col>
                            <div className={"erreur-404"}>
                                <p className={"label-size"}>4</p>
                                <div className={"image-error"}></div>
                                <p className={"label-size"}>4</p>
                            </div>
                            <span className={"text-error"}>Oooops, la page que vous cherchez n'existe pas !</span>
                        </Col>
                    </Row>
                </div>
            </Row>
        </div>
    );
};