import { createInvoice as createInvoiceService } from '../service/paymentService'

import {
  SAVE_DEVIS_ERROR,
  SAVE_DEVIS_FULLFILED,
  SAVE_DEVIS_PENDING,
  STORE_DEVIS_INFORMATIONS,
  RESET_DEVIS,
  SET_EXTERNAL_REF

} from '../reducers/type';



export const storeDevisInformations = (informationDevis) => ({
  type: STORE_DEVIS_INFORMATIONS,
  payload: informationDevis
});

export const saveDevisPending = () => ({
  type: SAVE_DEVIS_PENDING,
});

export const saveDevisFullfiled = (retourDevis) => ({
  type: SAVE_DEVIS_FULLFILED,
  payload: retourDevis
});

export const saveDevisError = (error) => ({
  type: SAVE_DEVIS_ERROR,
  payload: error
});

export const resetDevis = () => ({
    type: RESET_DEVIS
});

export const setExternalRef = (payload) => ({
  type: SET_EXTERNAL_REF,
  payload
});


export const createInvoice = (codeLogement, idPerson, idDevis, infoHamon) => (dispatch, getState) => {
  const state = getState();
  if (!state.devisReducer.devisCreated) {
    return createInvoiceService(codeLogement, idPerson, idDevis, infoHamon)
      .then(res => {
        dispatch(setExternalRef(res.externalRef))
        return res;
      })
  }
}