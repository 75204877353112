import { Button, Col, Form, Icon, Input, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import Modal from "antd/es/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getHousing } from "../service/houseService";
import { getCapital } from "../service/capitalService";
import { getLessor } from "../service/lessorService";
import { getHousingWrong } from '../actions/housing'
import Logo from "../components/logo/Logo";

const FormItem = Form.Item;

export default (props) => {
    const dispatch = useDispatch();
    const [codeLogement, setCodeLogement] = useState("");
    const housingReducer = useSelector(state => state.housingReducer);
    const lessorReducer = useSelector(state => state.lessorReducer);

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const secretHousingId = params.get('code');

    useEffect(() => {
        if (secretHousingId) {
            setCodeLogement(secretHousingId);
            dispatch(getHousing(secretHousingId, true))
        }
        dispatch(getLessor())
    }, []);

    function handleChangeCodeLogement(e) {
        setCodeLogement(e.target.value);
    }


    function handleSubmit(e) {
        e.preventDefault();
        dispatch(getHousing(codeLogement,false))
    }

    if (housingReducer.error !== undefined) {
        let codeError = housingReducer.error.response.data.errors[0];
        if (housingReducer.error.response.status === 404 && codeError === "BAI_002") {
            Modal.error({
                icon: "",
                content: <>
                  <p><strong>Cette référence ne correspond à aucun logement</strong></p>
                  <p>Si vous ne connaissez pas votre référence logement, rapprochez-vous de votre bailleur.</p>
                  <p>Pour réaliser votre devis <strong>sans code logement</strong>, contactez-nous au <a href='tel:0969394130'>09 69 39 41 30</a> de 9h à 18h du lundi au vendredi (coût d’un appel local).</p>
                  </>,
              onOk() {
                    dispatch(getHousingWrong())
                }
            });
        } else if (housingReducer.error.response.status === 400 && codeError === "BAI_001") {
            Modal.error({
                icon: "",
                content: 'Ce logement n\'est pas éligible',
                onOk() {
                    dispatch(getHousingWrong())
                }
            });
        } else {
            Modal.error({
                title: 'Erreur',
                content: 'Une erreur est survenue',
                onOk() {
                    dispatch(getHousingWrong())
                }
            });
        }
    }

    if (housingReducer.house !== undefined) {
        dispatch(getCapital(housingReducer.house.descriptif.nbPiece));
        return (<Redirect to='/logement' />)
    }


    return (<div className="login-form-wrapper">
        <Form onSubmit={e => handleSubmit(e)} className="login-form">
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Logo lessor={lessorReducer.lessor} />
            </div>
            <Row style={{ marginTop: 32 }} gutter={16} type={'flex'} justify={'center'}>
                <Col span={22} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Bienvenue chez {lessorReducer.lessor != undefined ? lessorReducer.lessor.information.label : ""}
                </Col>
                <Col span={22} style={{ marginTop: 16, textAlign: 'center' }}>
                    En tant que locataire <span style={{ fontWeight: 'bold' }}>{lessorReducer.lessor != undefined ? lessorReducer.lessor.information.label : ""}</span>, nous vous offrons la possibilité de souscrire
                    à un contrat d'assurance habitation complet et adapté à vos besoins.
                </Col>
                {!secretHousingId && <Col span={22} style={{ marginTop: 32 }}>
                    <FormItem label={<var>Saisissez la référence de votre logement <sup><i>(i)</i></sup></var>} colon={false} hasFeedback={true}>
                        <Input onChange={e => handleChangeCodeLogement(e)} name="codeLogement" value={codeLogement} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Référence" disabled={housingReducer.isPending} required
                        />
                    </FormItem>
                </Col>
                }
                <Col span={22} style={{ textAlign: 'center' }}>
                    {!housingReducer.isPending &&
                        <Button type="primary"
                            htmlType="submit"
                            className="login-form-button" >
                            Se connecter
                    </Button>
                    }
                    {housingReducer.isPending &&
                        <Spin style={{
                            paddingTop: 30
                        }} />
                    }
                </Col>
                <Col span={22} style={{ paddingTop: 30,textAlign: 'justify' }}>
                    <p><i>(i) Vous pouvez retrouver cette référence auprès de votre bailleur.</i></p>
                    <p><i>Cette référence peut se trouver, selon votre bailleur, sur votre quittance, votre espace
                        personnel ou sur les documents d’attribution de votre logement.<br></br><br></br>
                        Si vous souhaitez réaliser le devis par téléphone ou si vous ne connaissez pas votre code logement 
                        vous pouvez nous contacter au <br></br><a href='tel:0969394130'>09 69 39 41 30</a> de 9h à 18h du lundi au vendredi (coût d’un appel local).
                        </i>
                    </p>
                </Col>
            </Row>
        </Form>
    </div>)
}
