import { Col, Form, Row, Modal, Input, DatePicker, Select } from 'antd';
import RadioGroup from "antd/lib/radio/group";
import Radio from "antd/lib/radio/radio";
import Checkbox from "antd/es/checkbox";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { getCities } from '../../service/utilService';
import data from '../../donneesAssureur/assurreur.json'

moment.locale('fr');

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

const alreadyTenant = (props) => {

    const [isConsent, setIsConsent] = useState(false);
    const [isLessYear, setIsLessYear] = useState(undefined);
    const [citiesFiltered, setCitiesFiltered] = useState([]);
    const [assurranceFiltered, setAssuranceFiltered] = useState(undefined)
    const [assurranceSelected, setAssuranceSelected] = useState(undefined)
    const effectiveDateReducer = useSelector(state => state.effectiveDateReducer);

    const { getFieldDecorator } = props.form;

    const onInfo = () => {
        Modal.error({
            width: 600,
            title: <span style={{ color: '#FFBA00' }}>Avertissement</span>,
            content: (
                <div>
                    <p>Je ne peux pas souscrire maintenant. J'attends d'avoir un an d'assurance.
                        Je reviendrai sur le site Altima pour souscrire et Altima se chargera de la résiliation.
                    </p>
                </div>
            )
        });
    };

    function searchCities(query) {
        // Case city name filtered
        if (isNaN(Number(query)) && query.length >= 3) {
            getCities(query.toUpperCase()).then(data => {
                setCitiesFiltered(data)
                return data;
            })
            // Case  zipCode filtered
        } else if (!isNaN(Number(query)) && query.length >= 2) {
            getCities(query).then(data => {
                setCitiesFiltered(data)
                return data;
            })
        } else if (isNaN(Number(query)) && query.length < 3) {
            setCitiesFiltered([])
        } else if (!isNaN(Number(query)) && query.length < 2) {
            setCitiesFiltered([])
        }
    }

    function onChangeCity(value) {
        props.onChangeCity(citiesFiltered.filter(city => city.name + city.zipCode === value)[0])
    }

    const toggleRead = () => {
        props.acceptResilition(!isConsent);
    };

    function searchAssurrance(query) {
        const assurrancesFiltered = data.filter(assurrance => assurrance.nomAssureur.toLowerCase()
            .startsWith(query.toLowerCase()))
        setAssuranceFiltered(assurrancesFiltered)
    }

    function onChangeAssurrance(assurreur) {
        setAssuranceSelected(assurreur)
    }

    const onChangeYear = (e) => {
        setIsLessYear(e.target.value)
        props.onChangeYear(e.target.value)
    }

    if (isLessYear === true) {
        onInfo();
    }

    const hamonEffectiveDateFromNow = moment().add(34, "days")

    return (
        <div style={{ marginLeft: 17 }}>
            <FormItem style={{ color: 'rgba(0, 0, 0, .65)' }} colon={false} required>
                {getFieldDecorator('assureDepuisPlusUnAn', {
                    initialValue: effectiveDateReducer.effectiveDate ? moment(effectiveDateReducer.effectiveDate) : undefined,
                    rules: [{ required: true, message: 'Veuillez entrer la date de début' }],
                })(
                    <RadioGroup onChange={onChangeYear}>
                        <Radio style={props.radioStyle} value={true}>
                            Je suis assuré depuis moins d'un an
                        </Radio>
                        <Radio style={props.radioStyle} value={false}>
                            Je suis assuré depuis plus d'un an
                        </Radio>
                        {
                            isLessYear === false &&
                            <div>
                                <FormItem label="Mon futur logement sera assuré a partir du" style={{ color: 'rgba(0, 0, 0, .65)' }} hasFeedback={true} colon={false} required>
                                    {getFieldDecorator('hamonNewMrhSubscriptionStartDate', {
                                        initialValue: hamonEffectiveDateFromNow
                                    })(<DatePicker
                                        locale={locale}
                                        format="DD/MM/YYYY"
                                        style={{ width: '100%' }}
                                        value={hamonEffectiveDateFromNow}
                                        disabled={true} />
                                    )}
                                </FormItem>
                                <div style={{ marginTop: 16 }}>
                                    <FormItem style={{ lineHeight: '1.5', fontSize: 'normal' }} colon={false} required>
                                        {getFieldDecorator('consentement', {
                                            rules: [{ required: true, message: 'Veuillez cocher la case de consentement' }],
                                        })(
                                            <Checkbox onChange={toggleRead}><span style={{ color: 'rgba(255,0,0,1)' }}>* </span>
                                                <b>Je donne mandat à Altima pour résilier l'assurance actuelle de mon logement</b>
                                            </Checkbox>
                                        )}
                                    </FormItem>
                                </div>
                                <Row gutter={16} style={{ marginTop: 16 }}>
                                    <Col xs={24} lg={12}>
                                        <FormItem label="Prénom et nom souscripteur" hasFeedback={true} colon={false}>
                                            {getFieldDecorator('prenomNom', {
                                                rules: [{ required: true, message: 'Veuillez indiquer le prenom et le nom du souscripteur actuel' }],
                                            })(
                                                <Input
                                                    placeholder="Prénom et nom souscripteur" />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{ marginTop: 16 }}>
                                    <Col xs={24} lg={12}>
                                        <FormItem label="Compagnie d'assurance actuelle" hasFeedback={true} colon={false} required>
                                            {getFieldDecorator('assuranceActuelle', {
                                                rules: [{ required: true, message: 'Veuillez indiquer votre compagnie d\'assurance actuelle' }],
                                            })(
                                                <Select showSearch={true}
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    filterOption={false}
                                                    notFoundContent={null}
                                                    onSearch={e => searchAssurrance(e)}
                                                    onChange={e => onChangeAssurrance(e)}
                                                    placeholder="Nom de l'assurreur">

                                                    <OptGroup>
                                                        {assurranceFiltered !== undefined ? assurranceFiltered
                                                            .map((assurreur) =>
                                                                <Option key={assurreur.codeGTA}>{assurreur.nomAssureur}</Option>)
                                                            : data
                                                                .map((assurreur) =>
                                                                    <Option key={assurreur.codeGTA}>{assurreur.nomAssureur}</Option>)
                                                        }

                                                    </OptGroup>
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FormItem label="Numéro de contrat d'assurance" hasFeedback={true} colon={false}>
                                            {getFieldDecorator('contratAssurance', {
                                                rules: [{ required: true, message: 'Veuillez indiquer votre numéro de contrat de votre assurance actuelle' }],
                                            })(
                                                <Input
                                                    placeholder="AZE1234" />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{ marginTop: 16 }}>
                                    <Col xs={24} lg={12}>
                                        <FormItem label="Adresse de votre assureur" hasFeedback={true} colon={false} required>
                                            {getFieldDecorator('adresseAssureur', {
                                                rules: [{ required: true, message: 'Veuillez entrer l\'adresse de votre assureur' }],
                                            })(
                                                <Input placeholder="275 Rue du Stade" />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FormItem label="Ville de l'assureur" hasFeedback={true} colon={false} required>
                                            {
                                                getFieldDecorator('villeAssureur', {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Veuillez sélectionner la ville de votre assureur'
                                                    }],
                                                })(
                                                    <Select
                                                        showSearch={true}
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={e => searchCities(e)}
                                                        onChange={e => onChangeCity(e)}
                                                        notFoundContent={null}
                                                        placeholder="Paris">

                                                        {citiesFiltered.map((city) =>
                                                            <Option key={city.name + city.zipCode}>{city.name + ' (' + city.zipCode + ')'}</Option>)}

                                                    </Select>
                                                )
                                            }
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </RadioGroup>
                )}
            </FormItem>
        </div>
    );
}

export default (alreadyTenant)