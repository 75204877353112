import { Button, Card, Col, DatePicker, Divider, Form, Modal, Row, Spin } from "antd";
import RadioGroup from "antd/lib/radio/group";
import Radio from "antd/lib/radio/radio";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Step from "../components/step";
import AlreadyTenant from "../components/form/alreadyTenant";
import { createProposal } from "../service/devisService";
import { getTarif } from "../service/tarifService";
import { getHousingWrong } from "../actions/housing";
import { setEffectiveDate, setPayingDate } from "../actions/effectiveDate";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import WebCallBack from "../components/webCallBack/webCallBack";
import PreviousButton from "../components/form/PreviousButton";
import DevisInfo from "../components/devis/DevisInfo";
import RetractationInfo from "../components/devis/RetractationInfo";
import { calculateFirstDebit } from "../utils/numberUtils";
import { getDevis, getEffectiveDate, getHousing, getLessor, getPerson } from "../selectors";
import Consentements from "../components/devis/Consentements";

moment.locale("fr");

const FormItem = Form.Item;

const DevisWrapper = ({ form }) => {

    const history = useHistory();

    const [isFloat, setIsFloat] = useState(false);
    const [tarif, setTarif] = useState("");
    const [isNewTenant, setIsNewTenant] = useState(false);
    const [isConsent, setIsConsent] = useState(false);
    const [isLessYear, setIsLessYear] = useState(false);
    const [isVisibleRequest, setIsVisibleRequest] = useState(false);
    const [citySelected, setCitySelected] = useState(undefined);
    const [showOptions, setShowOptions] = useState(false);
    const [responsiveStyle, setResponsiveStyle] = useState({
        display: "block",
        height: "30px",
        lineHeight: "30px",
    });

    const dispatch = useDispatch();
    const housingReducer = useSelector(state => getHousing(state));
    const personReducer = useSelector(state => getPerson(state));
    const devisReducer = useSelector(state => getDevis(state));
    const lessorReducer = useSelector(state => getLessor(state));
    const effectiveDateReducer = useSelector(state => getEffectiveDate(state));

    const { getFieldDecorator, getFieldsError } = form;

    function alertError(e) {
        Modal.error({
            content: "Une erreur est survenue lors de l'enregistrement de votre devis. Prenez contact avec nos conseiller.",
            onOk() {
                dispatch(getHousingWrong());
                history.push("/");
            }
        });
    }

    useEffect(() => {
        if (devisReducer.idDevis) {
            history.push("/resume");
        }
        if (devisReducer.error) {
            alertError(devisReducer.error)
        }
        if (!housingReducer.isAuthenticated) {
            history.push("/");
        }

        if (housingReducer.house) {
            getTarif(housingReducer.house.housingCode).then(response => {
                setTarif(response[0].montantsAnnuel.mtTTC);
                const tarifFloat = response[0].montantsAnnuel.mtTTC;
                setIsFloat(tarifFloat.toString().includes("."));

                if (effectiveDateReducer.effectiveDate) {
                    setIsVisibleRequest(moment().add(14, "d") > moment(effectiveDateReducer.effectiveDate));
                }

            }).catch(error => {
                alertError(error);
            });
        }
        if (window.innerWidth <= 500) {
            setResponsiveStyle({
                height: "30px",
                lineHeight: "30px",
            });
        } else {
            setResponsiveStyle({
                display: "block",
                height: "30px",
                lineHeight: "30px",
            });
        }
    }, [devisReducer]);

    function handleSubmit(e) {
        e.preventDefault();
        form.validateFields((err, formValues) => {
            if (!err) {
                if (!formValues.assureDepuisPlusUnAn) {
                    if (formValues.startDate) {
                        dispatch(setEffectiveDate(formValues.startDate.format("DD/MM/YYYY")));
                        createProposalOnSubmit(formValues.startDate.format("YYYY-MM-DD"));
                        dispatch(setPayingDate(calculateFirstDebit(formValues.startDate)));
                    } else if (formValues.hamonNewMrhSubscriptionStartDate) {
                        if (citySelected) {
                            const informationAncienAssurreur = {
                                "prenomNom": formValues.prenomNom,
                                "codeGTA": formValues.assuranceActuelle,
                                "contratAssurance": formValues.contratAssurance,
                                "adresseAssureur": formValues.adresseAssureur,
                                "villeAssureur": citySelected.name,
                                "assureurZipCode": citySelected.zipCode
                            };
                            dispatch(setEffectiveDate(formValues.hamonNewMrhSubscriptionStartDate.format("DD/MM/YYYY")));
                            createProposalOnSubmit(formValues.hamonNewMrhSubscriptionStartDate.format("YYYY-MM-DD"), informationAncienAssurreur);
                            dispatch(setPayingDate(calculateFirstDebit(formValues.hamonNewMrhSubscriptionStartDate)));
                        }
                    }
                }
            }
        });
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    function alertError(e) {
        Modal.error({
            content: "Erreur lors de la récupération du tarif",
            onOk() {
                dispatch(getHousingWrong());
                //TODO REDIRECT
            }
        });
    }

    function disabledDate(current) {
        return current && current < moment().subtract(1, "d");
    }

    const onChangeTenantValue = (e) => {
        setIsNewTenant(e.target.value);
        setShowOptions(true);
        setIsConsent(false);
    };

    const droitRenonciation = (date, dateString) => {
        setIsVisibleRequest(moment().add(14, "d") > date);
    };

    function onChangeCity(birthCity) {
        setCitySelected(birthCity);
    }

    const onChangeYear = (value) => {
        setIsLessYear(value);
    };

    const createProposalOnSubmit = (date, informationAncienAssurreur) => {
        dispatch(
            createProposal(
                housingReducer.house.housingCode,
                personReducer.person,
                informationAncienAssurreur,
                date)
        );
    };

    let submitButtonDisabled = hasErrors(getFieldsError()) ||
        (!isNewTenant && isLessYear) ||
        (isNewTenant && isConsent);

    return (
        <div>
            <Step step={2} />
            <Row style={{ marginTop: "16px" }}>
                <div>
                    <Row type="flex" justify="end">
                        <Col span={2} style={{ marginRight: -6, marginTop: 32 }}>
                            <WebCallBack />
                        </Col>
                    </Row>
                    <Form onSubmit={e => handleSubmit(e)}>
                        <Spin spinning={devisReducer.isPending} tip="Enregistrement de votre devis...">
                            <div style={{ marginTop: 32 }}>
                                <DevisInfo tarif={tarif} isFloat={isFloat} />
                                <Col lg={{ offset: 1 }} style={{ width: "92%" }}>
                                    <Card
                                        bordered={false} style={{ marginTop: 32 }}
                                        title={"Prise d'effet du contrat"}
                                    >
                                        <FormItem colon={false} required>
                                            {getFieldDecorator("isSituation",
                                                {
                                                    rules: [{
                                                        required: true,
                                                        message: "Veuillez préciser votre situation"
                                                    }],
                                                })(
                                                    <RadioGroup onChange={onChangeTenantValue}>
                                                        <Radio style={responsiveStyle} value={true}>
                                                            Je suis un nouveau
                                                            locataire {lessorReducer.lessor ? lessorReducer.lessor.information.label : ""}
                                                        </Radio>
                                                        {
                                                            isNewTenant && showOptions &&
                                                            <div style={{ marginLeft: 17 }}>
                                                                <FormItem
                                                                    label="Je souhaite assurer mon logement à partir du (date de remise des clés)"
                                                                    style={{ color: "rgba(0, 0, 0, .65)" }} hasFeedback={true}
                                                                    colon={false} required>
                                                                    {getFieldDecorator("startDate", {
                                                                        rules: [{
                                                                            required: true,
                                                                            message: "Veuillez entrer la date de début"
                                                                        }],
                                                                    })(
                                                                        <DatePicker locale={locale} format="DD/MM/YYYY"
                                                                            placeholder="JJ/MM/AAAA"
                                                                            style={{ width: "100%" }}
                                                                            onChange={droitRenonciation}
                                                                            disabledDate={disabledDate} />
                                                                    )}
                                                                </FormItem>
                                                            </div>
                                                        }
                                                        <Radio style={responsiveStyle} value={false}>
                                                            Je suis déjà
                                                            locataire {lessorReducer.lessor ? lessorReducer.lessor.information.label : ""} et
                                                            je souhaite changer d'assureur
                                                        </Radio>
                                                        {
                                                            !isNewTenant && showOptions &&
                                                            <AlreadyTenant
                                                                form={form}
                                                                onChangeCity={onChangeCity}
                                                                onChangeYear={onChangeYear}
                                                                acceptResilition={() => { }}
                                                                radioStyle={responsiveStyle}
                                                            />
                                                        }
                                                    </RadioGroup>
                                                )}
                                        </FormItem>
                                        <Divider style={{ background: "#FFBA00" }} />
                                        <Row gutter={16} style={{ marginTop: 16 }}>
                                            <RetractationInfo />
                                            <Consentements
                                                value={isConsent}
                                                onChange={e => setIsConsent(e.target.value)}
                                                visible={isVisibleRequest && isNewTenant}
                                                getFieldDecorator={getFieldDecorator} />
                                        </Row>
                                    </Card>
                                </Col>
                                <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 32 }}>
                                    <PreviousButton url="/identite" />
                                    <Col xs={24} lg={4}>
                                        <FormItem>
                                            {!devisReducer.isPending &&
                                                <Button
                                                    type="primary"
                                                    style={{ width: "100%" }}
                                                    htmlType="submit"
                                                    disabled={submitButtonDisabled}
                                                >
                                                    Suivant
                                                </Button>
                                            }
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                        </Spin>
                    </Form>

                </div>
            </Row>
        </div>
    );
};

export default Form.create()(DevisWrapper);