import {SAVE_PERSON, SAVE_PERSON_ERROR, SAVE_PERSON_FULLFILED, SET_PERSON} from './type';

const defaultState = {
    person: undefined,
    idRefPerson: undefined,
    isPendingSave: false,
    personError: undefined
};


/**
 * Reducer pour l'objet person dans le store (infos perso souscripteur)
 * @param {*} state
 * @param {*} action
 */
export const personReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PERSON:
            return {
                ...state,
                person: action.payload
            };
        case SAVE_PERSON:
            return {
                ...state,
                isPendingSave: true
            };
        case SAVE_PERSON_FULLFILED:
            return {
                ...state,
                idRefPerson: action.payload.id,
                isPendingSave: false
            };
        case SAVE_PERSON_ERROR:
            return {
                ...state,
                personError: action.payload.id,
                isPendingSave: false
            };
        default:
            return state;
    }
}
