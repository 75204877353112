import {
    SAVE_DEVIS_ERROR,
    SAVE_DEVIS_FULLFILED,
    SAVE_DEVIS_PENDING,
    STORE_DEVIS_INFORMATIONS,
    RESET_DEVIS,
    SET_EXTERNAL_REF
} from './type';

const defaultState = {
    idRefPers: undefined,
    idDevis: undefined,
    idMaj: undefined,
    informationDevis: undefined,
    error: undefined,
    isPending: false,
    externalRef: undefined
};


/**
 *
 * @param {*} state
 * @param {*} action
 */
export const devisReducer = (state = defaultState, action) => {
    switch (action.type) {
        case STORE_DEVIS_INFORMATIONS:
            return {
                ...state,
                informationDevis: action.payload
            };

        case SAVE_DEVIS_PENDING:
            return {
                ...state,
                isPending: true
            };

        case SET_EXTERNAL_REF:
            return {
                ...state,
                externalRef: action.payload
            };

        case SAVE_DEVIS_FULLFILED:
            return {
                ...state,
                idDevis: action.payload.idDevis,
                idMaj: action.payload.idMaj,
                idRefPers: action.payload.refPersonId,
                error: undefined,
                isPending: false
            };
        case SAVE_DEVIS_ERROR:
            return {
                ...state,
                error: action.payload,
                isPending: false
            };
        case RESET_DEVIS:
            return {
                error: undefined,
                idMaj: undefined,
                informationDevis: undefined,
                idRefPers: undefined,
                idDevis: undefined,
                isPending: false
            };
        default:
            return state;
    }
};
