import { Button, Card, message, Spin, Col, Divider, Upload, Form, Icon, Input, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iban from "../components/bank/iban";
import Bic from "../components/bank/bic";
import Step from "../components/step";
import { Redirect, Link } from "react-router-dom";
import { getTarif } from "../service/tarifService";
import { checkIbanToBic } from "../service/paymentService";
import CotisationMessage from "../components/form/cotisationMessage";
import { updateProposal } from "../service/devisService";
import WebCallBack from "../components/webCallBack/webCallBack";
import { getHousingWrong } from "../actions/housing";

const FormItem = Form.Item;
const Dragger = Upload.Dragger;

const Bank = (props) => {

    const [error, isError] = useState();
    const [upload, isUploaded] = useState(false);
    const [isPendingTarif, setIsPendingTarif] = useState(true);
    const [isFloat, setIsFloat] = useState(false);
    const [ibanIsChecked, setIbanIsChecked] = useState(false);
    const [infoBank, setInfoBank] = useState({});
    const [errors, setErrors] = useState({});
    const [tarif, setTarif] = useState("");

    const dispatch = useDispatch();

    const housingReducer = useSelector(state => state.housingReducer);
    const devisReducer = useSelector(state => state.devisReducer);
    const effectiveDateReducer = useSelector(state => state.effectiveDateReducer);

    const refPersonId = devisReducer.idRefPers;
    const completeId = devisReducer.idDevis + "-" + devisReducer.idMaj;
    const { getFieldDecorator } = props.form;
    const { getFieldsError } = props.form;

    useEffect(() => {
        if (housingReducer.house !== undefined) {
            getTarif(housingReducer.house.housingCode).then(response => {
                setTarif(response[0].montantsAnnuel.mtTTC);
                let tarifFloat = response[0].montantsAnnuel.mtTTC;
                if (tarifFloat.toString().includes(".")) {
                    setIsFloat(true);
                } else {
                    setIsFloat(false);
                }
                setIsPendingTarif(false);
            }).catch(error => {
                setIsPendingTarif(false);
                alertError(error);
            });
        }
    }, []);

    if (housingReducer.house === undefined) {
        return (<Redirect to='/' />);
    }

    function checkIban(iban) {
        checkIbanToBic(iban).then(res => {
            setInfoBank({ ...infoBank, bic: res.bic });
            setIbanIsChecked(true);
        }).catch(error => {
            setIsPendingTarif(false);
            setErrors({
                ["ibanMsg"]: "Votre IBAN est invalide.",
                ["ibanStatus"]: error,
            });
        });
    }

    const onUploadChange = (info) => {
        const status = info.file.status;
        if (status !== "uploading") {
        }
        if (status === "done") {
            message.success(`${info.file.name} Le document a été téléchargé avec succès.`);
            isUploaded(true);
        } else if (status === "error") {
            // todo : remove!
            isUploaded(false);
            message.error(`${info.file.name} Le téléchargement du document a échoué.`);
        }
    };

    const beforeUpload = (file) => {
        const isGoodFormat = file.type.match(/^(image\/jpg|image\/jpeg|image\/png|application\/pdf)$/) !== null;
        if (!isGoodFormat) {
            message.error("Seuls les formats jpg, jpeg, png ou pdf sont acceptés.");
        }
        const isLess10M = file.size / 1024 / 1024 < 10;
        if (!isLess10M) {
            message.error("Le fichier doit faire moins de 10MB.");
        }
        return isGoodFormat && isLess10M;
    };

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    function handleSubmit(e) {
        e.preventDefault();
        const { iban, bic } = infoBank;
        props.form.validateFields((err, formValues) => {
            if (!err && iban && bic) {
                const informationsBancaires = {
                    "iban": iban,
                    "bic": bic,
                    "titulaireCompte": formValues.name
                };
                dispatch(updateProposal(devisReducer.idRefPers, devisReducer.idDevis, informationsBancaires));
                isError(false);
            } else {
                isError(true);
            }
        });
    }

    if (error === false) {
        return (<Redirect to='/paiement' ></Redirect>);
    }

    return (
        <div>
            <Step step={5} />
            <Row className="formBank" style={{ marginTop: "16px" }}>
                <div>
                    <Row type="flex" justify="end">
                        <Col span={2} style={{ marginRight: -6, marginTop: 32 }}>
                            <WebCallBack />
                        </Col>
                    </Row>
                    <Spin spinning={isPendingTarif}>
                        <Form onSubmit={e => handleSubmit(e)}>
                            <Row type={"flex"} justify={"center"}>
                                <Col span={24}>
                                    <div className='view-desktop'>
                                        <Divider><h2 style={{ fontSize: "2vw" }} className="step_title">Informations
                                            bancaires</h2></Divider>
                                    </div>
                                    <div className="view-mobile" style={{ display: "none" }}>
                                        <Divider><h3 style={{ fontSize: "4vw", margin: 0 }} className="step_title">Informations
                                            bancaires</h3></Divider>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <Card style={{ marginTop: 32 }} bordered={false}>
                                    <Row gutter={16}>
                                        <Row gutter={16}>
                                            <Col span={24} style={{ fontWeight: "bold", padding: "15px" }}>
                                                Pour souscrire, vous devez payer en ligne les deux premières mensualités
                                                par carte bancaire et saisir votre RIB qui servira pour le prélèvement des mois suivants.
                                            </Col>
                                        </Row>
                                        <Col span={24} style={{ marginTop: 16 }} border={false}>
                                            <div style={{ background: "#FFBA00", border: "#FFBA00", borderRadius: 3, marginBottom: "2em" }}>
                                                <CotisationMessage
                                                    tarif={tarif}
                                                    isFloat={isFloat}
                                                    effectiveDateReducer={effectiveDateReducer}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={24} lg={24 / 3}>
                                            <FormItem label="Nom du titulaire du compte" hasFeedback={true} required>
                                                {getFieldDecorator("name", {
                                                    rules: [{
                                                        message: "Veuillez entrer votre nom",
                                                        required: true
                                                    },
                                                    {
                                                        message: "Seules les lettres sont acceptées",
                                                        pattern: /^[a-zA-Z\-"\s"]+$/,
                                                    }],
                                                })(
                                                    <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                                                        placeholder="Nom" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} lg={24 / 3}>
                                            <Iban form={props.form} setInfoBank={setInfoBank} infoBank={infoBank} setIbanIsChecked={setIbanIsChecked} errors={errors} />
                                        </Col>
                                        <Col xs={24} lg={24 / 3}>
                                            {
                                                ibanIsChecked ?
                                                    <Bic form={props.form} currentBic={infoBank.bic} /> :
                                                    <Button
                                                        className="buttonValidIban"
                                                        type="primary"
                                                        onClick={() => checkIban(infoBank.iban)}
                                                        style={{ width: "100%" }}
                                                    >
                                                        Valider mon IBAN
                                                    </Button>
                                            }
                                        </Col>
                                    </Row>
                                    <Row gutter={16} type="flex" justify="center" align="top" style={{ marginTop: 16 }}>
                                        <Col xs={24} lg={12}>
                                            <FormItem label="Téléchargement de votre
                                                    RIB" hasFeedback={true} required>
                                                {getFieldDecorator("document", {
                                                    rules: [{
                                                        message: "Veuillez transmettre votre RIB",
                                                        required: true
                                                    }],
                                                })(
                                                    <Dragger onChange={onUploadChange}
                                                        name='file'
                                                        beforeUpload={beforeUpload}
                                                        action={"/api/v1/personnes/" + refPersonId + "/devis/" + completeId + "/documents/rib"}
                                                        required
                                                        // data={{
                                                        //     numClient: this.props.proposal.contractor.role.personId,
                                                        //     numProposal: this.props.proposalHandle.proposalId
                                                        // }}
                                                        accept='.jpg,.jpeg,.png,.pdf'>
                                                        <p className="ant-upload-drag-icon">
                                                            <Icon type="inbox" />
                                                        </p>
                                                        <p className="ant-upload-text">Cliquez ou faites glisser votre document
                                                            ici</p>
                                                        <p className="ant-upload-hint">Formats acceptés: jpg, jpeg, png ou pdf</p>
                                                        <p className="ant-upload-hint">Taille maximum du fichier: 10MB</p>
                                                    </Dragger>
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 32 }}>
                                <Col xs={24} lg={4}>
                                    <FormItem>
                                        <Link to='/conseil'>
                                            <Button
                                                type="secondary"
                                                style={{ width: "100%" }}
                                            >
                                                Précédent
                                            </Button>
                                        </Link>
                                    </FormItem>
                                </Col>
                                <Col xs={24} lg={4} >
                                    <FormItem>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={hasErrors(getFieldsError()) || !ibanIsChecked}
                                            style={{ width: "100%" }}
                                        >
                                            Suivant
                                        </Button>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div>
            </Row>
        </div >
    );
};

export default Form.create()(Bank);
