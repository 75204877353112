import { Col, Divider, Row, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';
import { getHowItWork } from "../../service/contentService";

export default () => {

    const [steps, setSteps] = useState([])

    useEffect(() => {
        getHowItWork().then(response => {
            if (response) {
                setSteps(response);
            }
        });
    }, [])

    return (
        <div>
            <div className='view-desktop'>
                <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Comment ça marche</h2></Divider>
            </div>
            <div className="view-mobile" style={{ display: 'none' }}>
                <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Comment ça marche</h3></Divider>
            </div>
            <Row>
                <Col span={24}>
                    <h3 className="howItWork-title">Contrat MULTIRISQUES HABITATION LOCATAIRE</h3>
                    <h3 className="howItWork-title">Comment ça marche ?</h3>
                </Col>
                <Col span={24} style={{ marginTop: 24 }}>
                    <b>Altima Assurances vous propose d'assurer votre logement en quelques clics.
                        Découvrez les 5 étapes clés !</b>
                </Col>
            </Row>
            <Row>
                <Col span={22} offset={2} style={{ marginTop: 36 }}>
                    <Timeline>
                        {
                            steps.map((step, index) =>
                                <Timeline.Item key={index}>
                                    <Row>
                                        <Col span={24}>
                                            <h4 dangerouslySetInnerHTML={{ __html: step.titre }} />
                                        </Col>
                                        {
                                            step.description &&
                                            <Col span={24}>
                                                <div dangerouslySetInnerHTML={{ __html: step.description.value }} />
                                            </Col>
                                        }
                                    </Row>
                                </Timeline.Item>)
                        }
                    </Timeline>
                </Col>
            </Row>
        </div>
    );
};