import { GET_EFFECTIVEDATE, SET_EFFECTIVEDATE, SET_EFFECTIVEDATE_ERROR, SET_PAYINGDATE, GET_PAYINGDATE } from './type';

const defaultState = { effectiveDate: undefined, payingDate: undefined, error: undefined, isPending: false };


/**
* Reducer pour l'objet housing dans le store 
* @param {*} state 
* @param {*} action 
*/
export const effectiveDateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_EFFECTIVEDATE:
      return {
        ...state,
        isPending: action.payload
      };

    case GET_PAYINGDATE:
      return {
        ...state,
        isPending: action.payload
      };

    case SET_EFFECTIVEDATE:
      return {
        ...state,
        effectiveDate: action.payload,
        error: undefined,
        isPending: false
      };

    case SET_PAYINGDATE:
      return {
        ...state,
        payingDate: action.payload,
        error: undefined,
        isPending: false
      };

    case SET_EFFECTIVEDATE_ERROR:
      return {
        ...state,
        error: action.payload,
        isPending: false
      };
    default:
      return state;
  }
};
