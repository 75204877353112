import { GET_INFO_LOIHAMON, SET_INFO_LOIHAMON, SET_INFO_LOIHAMON_ERROR, GET_INFO_LOIHAMON_PENDING } from './type';

const defaultState = { infoLoiHamon: undefined, error: undefined, isPending: false };


/**
* Reducer pour l'objet infoLoiHamon dans le store 
* @param {*} state 
* @param {*} action 
*/
export const infoLoiHamonReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_INFO_LOIHAMON:
      return {
        ...state,
        isPending: action.payload
      };

    case GET_INFO_LOIHAMON_PENDING:
      return {
        ...state,
        isPending: action.payload
      };

    case SET_INFO_LOIHAMON:
      return {
        ...state,
        infoLoiHamon: action.payload,
        error: undefined,
        isPending: false
      };

    case SET_INFO_LOIHAMON_ERROR:
      return {
        ...state,
        error: action.payload,
        isPending: false
      };
    default:
      return state;
  }
};
