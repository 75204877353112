import * as React from "react";
import { Input, Form, Button } from "antd";
import Icon from "antd/lib/icon";
import { useState } from "react";
import { checkIbanToBic } from "../../service/paymentService";
import { ibanChecksumValidation } from "./constante";

const FormItem = Form.Item;
// !Only fr IBan
// Todo: add other countries IBAN format
const Iban = (props) => {

    const [iban, setIban] = useState("");
    const [formattedIban, setFormattedIban] = useState("");
    const [ibanState, setIbanState] = useState(props.initialValue || "");

    const { getFieldDecorator } = props.form;
    // const { getFieldsError } = props.form;

    const format = (iban) => {
        if (!iban) {
            return "";
        }

        const cleanIban = iban
            .replace(/\s\s+/g, " ")
            .replace(/[^0-9a-zA-Z]/gi, "")
            .toUpperCase();

        const parts = [];

        if (cleanIban.length > 0) {
            parts.push(cleanIban.substring(0, 4));
        }

        if (cleanIban.length > 4) {
            parts.push(cleanIban.substring(4, 8));
        }

        if (cleanIban.length > 8) {
            parts.push(cleanIban.substring(8, 12));
        }

        if (cleanIban.length > 12) {
            parts.push(cleanIban.substring(12, 16));
        }

        if (cleanIban.length > 16) {
            parts.push(cleanIban.substring(16, 20));
        }

        if (cleanIban.length > 20) {
            parts.push(cleanIban.substring(20, 24));
        }

        if (cleanIban.length > 24) {
            parts.push(cleanIban.substring(24, 27));
        }

        return parts.join(" ");
    };

    function onChange(event) {
        const ibanInput = event.target.value || "";
        updateIbanState(ibanInput);
        props.setIbanIsChecked(false);
    }

    const updateIbanState = (input) => {
        const iban = input.replace(/[^0-9a-zA-Z]/gi, "").toUpperCase();
        setIban(iban);
        props.setInfoBank({ ...props.infoBank, iban: iban, bic: "" });
        setFormattedIban(format(iban));
    };

    return <FormItem label="IBAN" hasFeedback={true} required
        {...(props.errors.ibanMsg && { validateStatus: "error" })}
        {...(props.infoBank.bic && { validateStatus: "success" })}
        help={props.infoBank.bic ? "Votre IBAN est valide" : props.errors.ibanMsg}
    >
        <Input
            prefix={<Icon type="idcard" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="FR24 2002 0202 2606 0002 4M02 606"
            onChange={e => onChange(e)}
            value={formattedIban} />
    </FormItem>;
};

export default Iban;