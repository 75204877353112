import instance from './services'


export function sendConsent(idRefPers, mail, tel) {
    const url = encodeURI(`/api/v1/personnes/${idRefPers}/consentement`);
    const data = {
        "mailConsent": mail,
        "phoneConsent": tel
    };

    instance.post(url, data)
        .then(res => res.data)
        .then(response => {

            console.log("VGO v createProposal  response " + response);


        })
        .catch(error => {
            console.log("VGO createProposal  error " + error);

        }

        )
}

