import React, {useRef} from "react";

const IframePayment = ({externalRef}) => {

  const iFrame = useRef(null);
  const mobile = window.innerWidth <= 500;

  return(
    <>
      {
        mobile ?
          <div className="view-mobile" style={{ display: 'none' }}>
            <iframe
              src={`/api/v1/payment/facture/` + externalRef}
              style={{
                border: 'none',
                height: 550,
              }}
              sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin"
              onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
              scrolling="no"
            />
          </div>
          :
          <div className="view-desktop">
            <iframe
              ref={iFrame}
              name="iframe1"
              id="iframe1"
              src={`/api/v1/payment/facture/` + externalRef}
              style={{
                border: 'none',
                width: 700,
                height: 500,
              }}
              sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin"
            />

          </div>
      }
    </>
  );
};

export default IframePayment;