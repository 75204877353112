import React, { useEffect, useState } from 'react';
import { object, bool, string } from "prop-types";
import { Row, Spin } from 'antd';
import Highlight from '../../highlight';
import ListDocuments from '../../listeDocuments'
import Tarif from '../../tarif';
import FranchiseObjetsPrecieux from './FranchiseObjetsPrecieux'


const DevisInfo = ({ capitaux, isFloat, tarif }) => {

  const mensuel = isFloat ? (tarif / 12).toFixed(2) : (tarif / 12)

  return (
    <>
      <Row type="flex" justify="space-between">
        <Tarif mensuel={mensuel} annuel={tarif} offset={9} lg={6} titre={"Mon tarif"}/>}
      </Row>
      <Row gutter={16} type="flex" justify="space-around" style={{ marginTop: 64 }}>
        {capitaux ? <Highlight capital={capitaux}/> : <Spin/>}
        <ListDocuments/>
      </Row>
      <Row gutter={16} type="flex" justify="start" style={{ marginTop: 32 }}>
          <FranchiseObjetsPrecieux capitaux={capitaux} />
      </Row>
    </>
  );
}

DevisInfo.propTypes = {
  isFloat: bool.isRequired,
  tarif: string.isRequired,
  capitaux: object
}

export default DevisInfo