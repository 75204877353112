
import { GET_HOUSING_ASK, GET_HOUSING_ERROR, GET_HOUSING_FULLFILED, GET_HOUSING_WRONG } from './type';

const defaultState = { house: undefined, error: undefined, isPending: false, isAuthenticated: false }


/**
* Reducer pour l'objet housing dans le store (infos logement)
* @param {*} state 
* @param {*} action 
*/
export const housingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_HOUSING_ASK:
      return {
        ...state,
        isPending: true
      };

    case GET_HOUSING_FULLFILED:
      return {
        ...state,
        house: action.payload,
        error: undefined,
        isPending: false,
        isAuthenticated: true
      };
    case GET_HOUSING_ERROR:
        return {
          ...state,
          error: action.payload,
          isPending: false
        };
    case GET_HOUSING_WRONG:
        return {
          ...state,
          house: undefined,
          error: undefined,
          isAuthenticated: false
        };
    default:
      return state;
  }
}
