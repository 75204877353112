import { Card, Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR'
import { getCities } from '../../service/utilService';


moment.locale('fr');

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

export default (props) => {
    const [countriesFiltered, setCountriesFiltered] = useState(undefined)
    const [nationalitiesFiltered, setNationalitiesFiltered] = useState(undefined)
    const [citiesFiltered, setCitiesFiltered] = useState([])
    const [countrySelected, setCountrySelected] = useState(undefined)
    const countriesReducer = useSelector(state => state.countriesReducer)
    const personReducer = useSelector(state => state.personReducer)
    const { getFieldDecorator } = props.form;

    useEffect(() => {
        if (personReducer.person != undefined) setCountrySelected(personReducer.person.birthCountry)
    }, [])

    function disabledDate(current) {
        return current && current > moment().subtract(18, 'years');
    }

    function searchCountries(query) {
        const countriesFiltered = countriesReducer.countries.filter(country => country.nameFr.toLowerCase()
            .startsWith(query.toLowerCase()))
        setCountriesFiltered(countriesFiltered)
    }

    function searchNationalities(query) {
        const nationalitiesFiltered = countriesReducer.countries.filter(country => country.nameFr.toLowerCase()
            .startsWith(query.toLowerCase()))
        setNationalitiesFiltered(nationalitiesFiltered)
    }

    function onChangeBirthCity(value) {
        props.onChangeBirthCity(citiesFiltered.filter(city => city.name + city.zipCode === value)[0])
    }

    function searchCities(query) {
        // Case city name filtered
        if (isNaN(Number(query)) && query.length >= 3) {
            getCities(query.toUpperCase()).then(data => {
                setCitiesFiltered(data)
                return data;
            })
            // Case  zipCode filtered
        } else if (!isNaN(Number(query)) && query.length >= 2) {
            getCities(query).then(data => {
                setCitiesFiltered(data)
                return data;
            })
        } else if (isNaN(Number(query)) && query.length < 3) {
            setCitiesFiltered([])
        } else if (!isNaN(Number(query)) && query.length < 2) {
            setCitiesFiltered([])
        }
    }

    function onChangeBirthCountry(country) {
        setCountrySelected(country)
    }

    return (
        <Card title="Identité" bordered={false}>
            <Row gutter={16}>
                <Col xs={24} lg={4}>
                    <FormItem label="Civilité" hasFeedback={true} colon={false} required>
                        {getFieldDecorator('title', {
                            initialValue: personReducer.person ? personReducer.person.title : undefined,
                            rules: [{ required: true, message: 'Veuillez sélectionner votre civilité' }],
                        })(
                            <Select>
                                <Option value="01">M.</Option>
                                <Option value="02">Mme.</Option>
                            </Select>
                        )}
                    </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                    <FormItem label="Nom" hasFeedback={true} colon={false}>
                        {getFieldDecorator('lastName', {
                            initialValue: personReducer.person ? personReducer.person.lastname : undefined,
                            rules: [{ required: true, message: 'Veuillez entrer votre nom' },
                            {
                                message: 'Seules les lettres sont acceptées',
                                pattern: /^[àáâãäåçèéêëìíîïðòóôõöùúûüýÿ')a-zA-Z\-"\s"]+$/,
                            }],
                        })(
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Votre nom" />
                        )}
                    </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                    <FormItem label="Prénom" hasFeedback={true} colon={false} required>
                        {getFieldDecorator('firstName', {
                            initialValue: personReducer.person ? personReducer.person.firstname : undefined,
                            rules: [{ required: true, message: 'Veuillez entrer votre prénom' },
                            {
                                message: 'Seules les lettres sont acceptées',
                                pattern: /^[àáâãäåçèéêëìíîïðòóôõöùúûüýÿ')a-zA-Z\-"\s"]+$/,
                            }],
                        })(
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.5)' }} />}
                                placeholder="Votre prénom" />
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} lg={4}>
                    <FormItem label="Date de naissance" hasFeedback={true} colon={false} required>
                        {getFieldDecorator('birthdate', {
                            initialValue: personReducer.person ? moment(personReducer.person.birthdate) : undefined,
                            rules: [{ required: true, message: 'Veuillez entrer votre date de naissance' }],
                        })(
                            <DatePicker locale={locale} format="DD/MM/YYYY" placeholder="JJ/MM/AAAA"
                                style={{ width: '100%' }}
                                disabledDate={disabledDate} />
                        )}
                    </FormItem>
                </Col>

                <Col xs={24} lg={8}>
                    <FormItem label="Pays de naissance" hasFeedback={true} colon={false} required>
                        {getFieldDecorator('birthCountry', {
                            initialValue: personReducer.person ? personReducer.person.birthCountry : '',
                            rules: [{
                                required: true,
                                message: 'Veuillez sélectionner votre pays de naissance'
                            }],
                        })(
                            <Select showSearch={true}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                notFoundContent={null}
                                onSearch={e => searchCountries(e)}
                                onChange={e => onChangeBirthCountry(e)}
                                placeholder="France">

                                <OptGroup label='Pays'>
                                    <Option key={'FR'}>France</Option>
                                </OptGroup>
                                {countriesReducer.countries === undefined ? "" :
                                    <OptGroup label='Autres pays'>

                                        {countriesFiltered === undefined ? countriesReducer.countries
                                            .filter(country => country.alpha2.toLowerCase() !== 'fr')
                                            .sort((countryA, countryB) => countryA.nameFr.localeCompare(countryB.nameFr))
                                            .map((country) =>
                                                <Option key={country.alpha2}>{country.nameFr}</Option>)
                                            : countriesFiltered
                                                .filter(country => country.alpha2.toLowerCase() !== 'fr')
                                                .sort((countryA, countryB) => countryA.nameFr.localeCompare(countryB.nameFr))
                                                .map((country) =>
                                                    <Option key={country.alpha2}>{country.nameFr}</Option>)}

                                    </OptGroup>
                                }
                            </Select>)}
                    </FormItem>
                </Col>
                <Col xs={24} lg={8}>
                    <FormItem label="Commune de naissance" hasFeedback={true} colon={false} required>
                        {countrySelected !== undefined && countrySelected.toLowerCase() === "fr" ?
                            getFieldDecorator('birthZipCode', {
                                initialValue: personReducer.person ? personReducer.person.birthCity + ' (' + personReducer.person.birthZipCode + ')' : '',
                                rules: [{
                                    required: true,
                                    message: 'Veuillez sélectionner votre commune de naissance'
                                }],
                            })(
                                <Select
                                    showSearch={true}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={e => searchCities(e)}
                                    onChange={e => onChangeBirthCity(e)}
                                    notFoundContent={null}
                                    placeholder="Paris">

                                    {citiesFiltered.map((city) =>
                                        <Option key={city.name + city.zipCode}>{city.name + ' (' + city.zipCode + ')'}</Option>)}

                                </Select>)
                            :
                            getFieldDecorator('birthZipCode', {
                                initialValue: personReducer.person ? personReducer.person.birthCity : '',
                                rules: [{
                                    required: true,
                                    message: 'Veuillez entrer votre commune de naissance'
                                },
                                {
                                    message: 'Seules les lettres sont acceptées',
                                    pattern: /^[àáâãäåçèéêëìíîïðòóôõöùúûüýÿ)a-zA-Z\-"\s"]+$/,
                                }],
                            })(
                                <Input placeholder="Paris" />)
                        }
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={8}>

                    <FormItem label="Nationalité" hasFeedback={true} colon={false} required>
                        {getFieldDecorator('nationality', {
                            initialValue: personReducer.person ? personReducer.person.nationality : '',
                            rules: [{
                                required: true,
                                message: 'Veuillez sélectionner votre nationalité'
                            }],
                        })(
                            <Select showSearch={true}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                notFoundContent={null}
                                onSearch={e => searchNationalities(e)}
                                placeholder="France">

                                <OptGroup label='Pays'>
                                    <Option key={'FR'}>France</Option>
                                </OptGroup>

                                {countriesReducer.countries === undefined ? "" :
                                    <OptGroup label='Autres pays'>
                                        {nationalitiesFiltered === undefined ? countriesReducer.countries
                                            .filter(country => country.alpha2.toLowerCase() !== 'fr')
                                            .sort((countryA, countryB) => countryA.nameFr.localeCompare(countryB.nameFr))
                                            .map((country) =>
                                                <Option key={country.alpha2}>{country.nameFr}</Option>)
                                            : nationalitiesFiltered
                                                .filter(country => country.alpha2.toLowerCase() !== 'fr')
                                                .sort((countryA, countryB) => countryA.nameFr.localeCompare(countryB.nameFr))
                                                .map((country) =>
                                                    <Option key={country.alpha2}>{country.nameFr}</Option>)}
                                    </OptGroup>}
                            </Select>)}
                    </FormItem>
                </Col>
            </Row>
        </Card>);
}
