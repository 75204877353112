import { GET_CAPITAUX, SET_CAPITAUX, SET_CAPITAUX_ERROR } from './type';

const defaultState = { capitaux: undefined, error: undefined, isPending: false };


/**
* Reducer pour l'objet housing dans le store (infos logement)
* @param {*} state 
* @param {*} action 
*/
export const capitauxReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CAPITAUX:
      return {
        ...state,
        isPending: action.payload
      };

    case SET_CAPITAUX:
      return {
        ...state,
        capitaux: action.payload,
        error: undefined,
        isPending: false
      };
    case SET_CAPITAUX_ERROR:
        return {
          ...state,
          error: action.payload,
          isPending: false
        };
    default:
      return state;
  }
};
