import { Row, Divider, Descriptions } from "antd";
import * as React from "react";
import { useSelector } from "react-redux";

export default () => {
    const housingReducer = useSelector(state => state.housingReducer);
    return (
        <div>
            <Row gutter={16}>
                <Descriptions>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Référence logement : </label>
                            {housingReducer.house.housingCode}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Type de logement : </label>
                            {housingReducer.house.descriptif.categorie}</span>
                        </div>
                    </Descriptions.Item>
                    {housingReducer.house.descriptif.categorie === "APPT" &&
                        <Descriptions.Item>
                            <div className={"resume-content"}>
                                <span><label className={"resume"}>Etage : </label>
                                {housingReducer.house.adressLogement.etage}</span>
                            </div>
                        </Descriptions.Item>}
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Nombre de pièces : </label>
                            {housingReducer.house.descriptif.typeCommercial}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Surface habitable : </label>
                            {housingReducer.house.descriptif.livingSurface + " m²"}</span>
                        </div>
                    </Descriptions.Item>
                </Descriptions>
                <Divider orientation="left">Adresse du logement</Divider>
                <div className={"containerInfoHouse"}>
                    <div className="columnInfoHouse">
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Résidence : </label>{housingReducer.house.adressLogement.residence}</span>
                        </div>
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Ville : </label>{housingReducer.house.adressLogement.city}</span>
                        </div>
                    </div>
                    <div className="columnInfoHouse">
                        <div className={"resume-content"}>
                            <label className={"resume"}>Adresse : </label>
                            <div className="adresseBailleur">
                                <span>{housingReducer.house.adressLogement.adresse}</span>
                                {housingReducer.house.adressLogement.adresse2 != "" && <span>{housingReducer.house.adressLogement.adresse2}</span>}
                                {housingReducer.house.adressLogement.adresse3 != "" && <span>{housingReducer.house.adressLogement.adresse3}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="columnInfoHouse">
                        <div className={"resume-content"}>
                            <span><label className={"resume"}>Code postal : </label>{housingReducer.house.adressLogement.postalCode}</span>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};