import { Col, Card, Row } from 'antd';
import * as React from 'react';
import logo from "../waow.png";
import { useState } from "react";
import { useEffect } from "react";



export default ({ lg, offset, mensuel, annuel, titre }) => {
    const [mobile, setMobile] = useState({ marginBottom: '16px' });

    useEffect(() => {
        if (window.innerWidth <= 500) {
            setMobile({ marginBottom: '0px' })
        } else {
            setMobile({ marginBottom: '16px' })
        }
    }, []);

    let affichageMensuel = null;
    let affichageAnnuel = null;

    // tester si mensuel et bien un  chiffre,
    if (mensuel !== null) {
        if (parseFloat(mensuel.toString())) {
            affichageMensuel = mensuel.toString().replace(".", ",");
        }
    }

    if (annuel !== null) {
        if (parseFloat(annuel.toString())) {
            affichageAnnuel = annuel.toString().replace(".", ",");
        }
    }

    return (
        <Col xs={24} lg={lg} offset={offset}>
            <Card id="price" bordered={false} style={mobile}>
                <Row type='flex' justify='center'>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <span style={{ color: 'white', textAlign: 'center', fontSize: 24 }}>{titre}</span>
                    </Col>
                </Row>
                <Row hidden={false} type='flex' justify='center'>
                    <Col span={14} style={window.innerWidth >= 370 ?
                      { fontSize: 44, fontWeight: 'bold', color: 'white', textAlign: 'center' } :
                      { fontSize: 30, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                        {affichageMensuel}
                        <span> €</span>
                        <span style={{ fontSize: 20, fontWeight: 'normal' }}>/mois</span>
                    </Col>
                    <Col span={6} style={{ textAlign: 'left' }}>
                        <img id="logo" src={logo}
                            style={{
                                display: 'block',
                                maxWidth: '40px',
                                maxHeight: '40px',
                                width: 'auto',
                                height: 'auto'
                            }}
                            alt="Logo waow" />
                    </Col>
                </Row>
                {
                    affichageAnnuel !== null &&
                    <Row hidden={false} type='flex' justify='center'>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <div style={{ fontStyle: 'italic', color: 'white', textAlign: 'center' }}>
                                <span style={{ fontSize: 18, marginLeft: 20, marginRight: 20 }}>
                                    soit {affichageAnnuel} €/an
                                </span>
                            </div>
                        </Col>
                    </Row>
                }


            </Card>
        </Col>
    );
}