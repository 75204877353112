import instance from './services'

export function verifToken(token) {
        const url = encodeURI("/api/v1/upload/token/" + token);
        return instance.get(url)
                .then(res => {
                        return res.data
                })
}

export function updateToken(token) {
        const url = encodeURI("/api/v1/upload/token/" + token);
        return instance.post(url)
                .then(res => {
                        return res.data
                })
}