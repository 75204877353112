import { Button, Card, Spin, Checkbox, Col, Divider, Form, Row, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import Step from '../components/step';
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTarif } from "../service/tarifService";
import WebCallBack from "../components/webCallBack/webCallBack";
import { createInvoice } from '../actions/devis';
import { getDevis, getHousing, getInfoLoiHamon, getEffectiveDate } from '../selectors';
import IframePayment from '../components/payment/IframePayment';

const FormItem = Form.Item;

const payment = () => {

    const dispatch = useDispatch();

    const [displayPayment, setDisplayPayment] = useState(false);
    const [tarif, setTarif] = useState("");
    const [isPendingTarif, setIsPendingTarif] = useState(false);
    const [isPendingPaynum, setIsPendingPaynum] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);

    const housingReducer = useSelector(state => getHousing(state));
    const devisReducer = useSelector(state => getDevis(state));
    const infoLoiHamonReducer = useSelector(state => getInfoLoiHamon(state));
    const effectiveDateReducer = useSelector(state => getEffectiveDate(state));

    useEffect(() => {
        setIsPendingTarif(true)
        if (housingReducer.house) {
            getTarif(housingReducer.house.housingCode).then(response => {
                setTarif(response[0].montantsAnnuel.mtTTC);
                setIsPendingTarif(false);
                if (devisReducer.idDevis && !devisReducer.externalRef) {

                    setIsPendingPaynum(true);
                    dispatch(
                      createInvoice(
                        housingReducer.house.housingCode,
                        devisReducer.idRefPers,
                        devisReducer.idDevis,
                        infoLoiHamonReducer.infoLoiHamon))
                      .then(() => {
                          setIsPendingPaynum(false);
                      })
                      .catch(() => {
                          setIsPendingPaynum(false);
                          setIsLoadingError(true);
                      })
                }
            }).catch(error => {
                setIsPendingTarif(false);
                alertError(error, true);
            });
        }
    }, []);

    function alertError(e, isTarifError = false) {
        Modal.error({
            content: "Erreur de chargement :" + e + (isTarifError ? "." : ". Voulez-vous recharger ?"),
            onOk() {
                !isTarifError && reload();
            }
        });
    }

    // Fix : Cotisation message afficher le bon tarif

    const reload = () => {
        setIsPendingPaynum(true);
        dispatch(
          createInvoice(
            housingReducer.house.housingCode,
            devisReducer.idRefPers,
            devisReducer.idDevis,
            infoLoiHamonReducer.infoLoiHamon))
          .then(() => {
              setIsPendingPaynum(false);
              setIsLoadingError(false);
          }).catch(error => {
            setIsPendingPaynum(false);
            alertError(error);
          })
    }

    const onCheckContrat = () => {
        if (isLoadingError && !devisReducer.externalRef) {
            alertError("Informations manquantes");
        }
        setDisplayPayment(!displayPayment);
    };

    if (!housingReducer.house) {
        return (<Redirect to='/' />)
    }

    const isFloat = tarif.toString().includes(".");

    return (
        <div>
            <Step step={6} />
            <Row style={{ marginTop: '16px' }}>
                <Row type="flex" justify="end">
                    <Col span={2} style={{ marginRight: -6, marginTop: 32 }}>
                        <WebCallBack />
                    </Col>
                </Row>
                <Spin spinning={isPendingTarif}>
                    <div>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row type={'flex'} justify={'center'}>
                                <Col span={24}>
                                    <div className='view-desktop'>
                                        <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Paiement par carte
                                    bancaire</h2></Divider>
                                    </div>
                                    <div className="view-mobile" style={{ display: 'none' }}>
                                        <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Paiement par carte
                                    bancaire</h3></Divider>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <Card style={{ marginTop: 32 }} bordered={false}>
                                    <Row gutter={16}>
                                        <Row gutter={16}>
                                            {
                                                isFloat &&
                                                <Col span={24} style={{ fontWeight: 'bold', padding: '15px' }}>
                                                    Le paiement des deux premières échéances soit <label style={{ fontSize: '16px' }}>{isFloat ? (tarif / 12).toFixed(2) * 2 : (tarif / 12) * 2} €</label> par carte bancaire
                                                    materialise la souscription du contrat qui débutera le
                                                    <label style={{ fontSize: '16px' }}>
                                                        {
                                                            effectiveDateReducer.effectiveDate !== "" &&
                                                            " " + effectiveDateReducer.effectiveDate
                                                        }
                                                    </label>
                                                </Col>
                                            }
                                        </Row>
                                        <Row gutter={16} style={{ marginTop: 32 }}>
                                            <Col span={24}>
                                                <Checkbox onChange={onCheckContrat}>
                                                    <span style={{ color: 'rgba(255,0,0,1)' }}>* </span>
                                                    Je reconnais avoir reçu et pris connaissance des documents précontractuels
                                                    et contractuels
                                                <a target="_blank" href={`${window.location.protocol}//${window.location.host}/garanties/?login=${housingReducer.house.housingCode}`}> lire ici </a>
                                                    et en accepte les conditions.
                                            </Checkbox>
                                            </Col>
                                        </Row>
                                        {displayPayment &&
                                        <Row gutter={16} style={{ textAlign: "center" }}>
                                            <Spin spinning={isPendingPaynum}>
                                                <IframePayment externalRef={devisReducer.externalRef}/>
                                            </Spin>
                                        </Row>}
                                    </Row>
                                </Card>
                            </div>
                            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 32 }}>
                                <Col xs={24} lg={4}>
                                    <FormItem>
                                        <Link to='/renseignement-bancaires'>
                                            <Button
                                                type="secondary"
                                                style={{ width: '100%' }}
                                            >
                                                Précédent
                                            </Button>
                                        </Link>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Spin>
            </Row>
        </div >
    );
};

export default Form.create()(payment)
