
import {
  GET_COUNTRIES_FULLFILED,
  GET_COUNTRIES_ERROR,
  GET_PROFESSIONS_FULLFILED,
  GET_PROFESSIONS_ERROR
} from './type';

const defaultState = { countries: undefined, professions: undefined, errors:[] }


/**
* Reducer pour l'objet countries dans le store (liste pays)
* @param {*} state 
* @param {*} action 
*/
export const countriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_COUNTRIES_FULLFILED:
      return {
        ...state,
        countries: action.payload,
        errors: []
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};


export const professionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROFESSIONS_FULLFILED:
      return {
        ...state,
        professions: action.payload,
        errors: []
      };
    case GET_PROFESSIONS_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
