import { Button, Col, Divider, Row, Spin } from 'antd';
import Modal from "antd/es/modal/Modal";
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHousingWrong } from '../actions/housing';
import { getTarif } from '../service/tarifService'
import Step from '../components/step';
import ResumeLogement from '../components/resume/resumeLogement';


export default () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [mobile, setMobile] = useState({ marginTop: '16px' });
    const housingReducer = useSelector(state => state.housingReducer);
    const [isPendingTarif, setIsPendingTarif] = useState(true);
    const [tarif, setTarif] = useState("");

    useEffect(() => {
        if (!housingReducer.isAuthenticated) {
            history.push('/')
        }
        if (housingReducer.house !== undefined) {
            getTarif(housingReducer.house.housingCode).then(response => {
                setTarif(response[0].montantsAnnuel.mtTTC);
                setIsPendingTarif(false);
            }).catch(error => {
                setIsPendingTarif(false);
                alertError(error);
            })
        }
        if (window.innerWidth <= 500) {
            setMobile({ marginTop: '0px' })
        } else {
            setMobile({ marginTop: '16px' })
        }
    }, []);

    function alert(e) {
        Modal.warning({
            content: "Je vérifie la saisie du code logement. Si le code logement est correct et l’adresse ou le " +
                "descriptif inexacts, je me rapproche de mon bailleur qui est le seul à pouvoir modifier ces informations.",
            onOk() {
                dispatch(getHousingWrong());
                history.push('/');
            }
        });
    }

    function alertError(e) {
        Modal.error({
            content: "Erreur lors de la récupération du tarif",
            onOk() {
                dispatch(getHousingWrong());
                history.push('/');
            }
        });
    }


    return (
        <div>
            <Step step={0} />
            <Row style={mobile}>
                <div>

                    <div className='view-desktop'>
                        <Row type={'flex'} justify={'center'}>
                            <Col span={24}>
                                <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Je vérifie les informations de mon logement</h2></Divider>
                            </Col>
                        </Row>
                    </div>
                    <div className="view-mobile" style={{ display: 'none' }}>
                        <Row type={'flex'} justify={'center'}>
                            <Col span={24}>
                                <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Je vérifie les informations de mon logement</h3></Divider>
                            </Col>
                        </Row>
                    </div>
                    <Spin spinning={isPendingTarif}>
                        <ResumeLogement house={housingReducer.house} tarif={tarif} />
                    </Spin>



                    <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 32 }}>
                        <Col xs={24} lg={6} >
                            <Button
                                type="secondary"
                                style={{ width: '100%' }}
                                onClick={e => alert(e)}
                            >
                                Les informations sont inexactes
                        </Button>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Link to='/identite'>
                                <Button
                                    type="primary"
                                    style={{ width: '100%' }}
                                >
                                    C'est bien mon logement
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Row>
        </div>
    );
}
