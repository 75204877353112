import axios from 'axios'

const instance = axios.create({
  timeout: 4000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    // 'dev-With-Love': "!!!!"
  }
});

// instance.interceptors.request.use(
//   config => {
//     if (window.localStorage.getItem('admin') === "true") {
//       console.log("asAdmin call")
//       config.headers.role = `admin`;
//     }
//     return config;
//   },
//   error => Promise.reject(error)
// );

export default instance;