import {Button, Card, Col, Divider, Form, Icon, Input, Row, Select} from "antd";
import Modal from "antd/es/modal/Modal";
import React, {useState, useEffect} from "react";
import {Redirect, Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Person from "../components/person/person";
import Step from "../components/step";
import NumberFormat from "react-number-format";
import Radio from "antd/lib/radio/radio";
import RadioGroup from "antd/lib/radio/group";
import {getCountries, getProfessions} from "../service/utilService";
import {setPersonFullfiled} from "../actions/person";
import PreviousButton from "../components/form/PreviousButton";

const FormItem = Form.Item;
const Option = Select.Option;

const PersonWrapper = (props) => {
    const dispatch = useDispatch();
    const [isCompany, setIsCompany] = useState(false);
    const [isPersonSave, setIsPersonSave] = useState(false);
    const [citySelected, setCitySelected] = useState(undefined);
    const [professionsFiltered, setProfessionsFiltered] = useState(undefined);
    const housingReducer = useSelector(state => state.housingReducer);
    const personReducer = useSelector(state => state.personReducer);
    const professionsReducer = useSelector(state => state.professionsReducer);
    const {getFieldDecorator} = props.form;
    const {getFieldsError} = props.form;


    function handleSubmit(e) {
        e.preventDefault();
        props.form.validateFields((err, formValues) => {
            if (!err) {
                let city = formValues.birthZipCode;
                let zipCode = "";
                if (formValues.birthCountry.toLowerCase() === "fr") {
                    if (citySelected === undefined) {
                        city = personReducer.person.birthCity;
                        zipCode = personReducer.person.birthZipCode;
                    } else {
                        city = citySelected.name;
                        zipCode = citySelected.zipCode;
                    }
                }

                const person = {
                    "title": formValues.title,
                    "lastname": formValues.lastName,
                    "firstname": formValues.firstName,
                    "birthdate": formValues.birthdate.format("YYYYMMDD"),
                    "birthCountry": formValues.birthCountry,
                    "birthCity": city,
                    "birthZipCode": zipCode,
                    "nationality": formValues.nationality,
                    "mail": formValues.email,
                    "phone": formValues.genericPhone,
                    "profession": formValues.profession
                };
                dispatch(setPersonFullfiled(person));
                setIsPersonSave(true);
            }
        });
    }

    useEffect(() => {
        dispatch(getCountries());
        dispatch(getProfessions());
        if (housingReducer.house === null) {
            return (<Redirect to='/'/>);
        }
    }, []);

    function searchProfessions(query) {
        const professionsFiltered = (professionsReducer.professions || []).filter(profession => profession.label.toLowerCase()
            .startsWith(query.toLowerCase()));
        setProfessionsFiltered(professionsFiltered)
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    function onProfessionalChange(e) {
        if (e.target.value) {
            setIsCompany(true);
            Modal.error({
                content: "Ce contrat ne couvre pas votre responsabilité civile professionnelle ni les biens détenus à usage professionnel. \n Nous vous rappelons que cette assurance est obligatoire et nous vous conseillons de vous rapprocher d'un autre assureur afin de couvrir votre activité professionnelle."
            });
        } else {
            setIsCompany(false);
        }

    }

    const onInfo = () => {
        Modal.info({
            title: <span style={{color: "#052B4F"}}>Informations</span>,
            content: (
                <div>
                    <p>Cochez « oui » si une ou plusieurs pièces principales de votre habitation sont utilisées pour une activité professionnelle (assistante maternelle ou autre travail à domicile).</p>
                    <p>Notre contrat ne comprend pas de Responsabilité Civile professionnelle et ne couvre pas les biens détenus à usage professionnel (si la facture du bien est au nom d'une société).</p>
                </div>
            )
        });
    };

    function onChangeBirthCity(birthCity) {
        setCitySelected(birthCity);
    }

    if (isPersonSave) {
        return (<Redirect to='/devis'></Redirect>);
    }

    if (!housingReducer.isAuthenticated) {
        return (<Redirect to='/'></Redirect>);
    }

    return (
        <div>
            <Step step={1}/>
            <Row style={{marginTop: "16px"}}>
                <Form onSubmit={e => handleSubmit(e)}>
                    <div className='view-desktop'>
                        <Divider><h2 style={{ fontSize: "2vw" }} className="step_title">Je complète mes informations personnelles</h2></Divider>
                    </div>
                    <div className="view-mobile" style={{display: "none"}}>
                        <Divider><h3 style={{ fontSize: "4vw", margin: 0 }} className="step_title">Je complète mes informations personnelles</h3></Divider>
                    </div>
                    <Person form={props.form} onChangeBirthCity={onChangeBirthCity}/>
                    <Card style={{marginTop: "16px"}}
                          bordered={false}
                          title="Contact">
                        <Row gutter={16}>
                            <Col xs={24} lg={6}>
                                <FormItem label="E-mail" hasFeedback={true} colon={false} required>
                                    {getFieldDecorator("email", {
                                        initialValue: personReducer.person ? personReducer.person.mail : undefined,
                                        rules: [{
                                            message: "Veuillez entrer votre email",
                                            required: true,
                                        }, {
                                            message: "Veuillez entrer un email valide!",
                                            type: "email",
                                        }],
                                    })(
                                        <Input prefix={<Icon type="mail" style={{color: "rgba(0,0,0,.25)"}}/>}
                                               placeholder="email@exemple.com"/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={24} lg={6}>
                                <FormItem label="Téléphone (portable de préférence)" hasFeedback={true} colon={false} required>
                                    {getFieldDecorator("genericPhone", {
                                        initialValue: personReducer.person ? personReducer.person.phone : undefined,
                                        rules: [{
                                            message: "Veuillez entrer votre numéro de téléphone",
                                            required: true,
                                        }, {
                                            max: 14,
                                            message: "Veuillez entrer un numéro de téléphone valide",
                                            pattern: /0[12345679]( ?[0-9][0-9]){4}$/,
                                        }],
                                    })(
                                        <NumberFormat format="## ## ## ## ##" placeholder="06 01 01 01 01" customInput={Input} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{marginTop: 16}}
                          bordered={false}
                          title="Activite professionnelle">
                        <Row type="flex" justify="start">
                            <Col xs={24} lg={10}>
                                <FormItem label="Profession" hasFeedback={true} colon={false} required>
                                    {
                                        getFieldDecorator('profession', {
                                            initialValue: personReducer.person ? personReducer.person.profession : '',
                                            rules: [{
                                                required: true,
                                                message: 'Veuillez sélectionner votre profession'
                                            }],
                                        })(
                                            <Select showSearch={true}
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    filterOption={false}
                                                    notFoundContent={null}
                                                    onSearch={e => searchProfessions(e)}
                                                    placeholder="Profession">
                                                {professionsReducer.professions === undefined ? ""
                                                    : professionsFiltered === undefined ? professionsReducer.professions
                                                            .map((profession) =>
                                                                <Option key={profession.id}>{profession.label}</Option>)
                                                        : professionsFiltered
                                                            .map((profession) =>
                                                                <Option
                                                                    key={profession.id}>{profession.label}</Option>)
                                                }
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" justify="start">
                            <Col xs={24} lg={10}>
                                <FormItem label={
                                    <span onClick={onInfo} style={{textAlign: "left"}}>
                                        Prévoyez-vous d'exercer une activité professionnelle à domicile ? <Icon type="question-circle" style={{ fontSize: 20, paddingTop: 15, color: "rgba(0,0,0,.25)" }} />
                                    </span>}
                                          colon={false} required>

                                    {getFieldDecorator("isCompany",
                                        {
                                            rules: [{
                                                required: true,
                                                message: "Veuillez préciser si vous exercez une activité professionnelle à domicile"
                                            }],
                                        })(
                                        <RadioGroup onChange={onProfessionalChange}>
                                            <Radio value={true}>Oui</Radio>
                                            <Radio value={false}>Non</Radio>
                                        </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Card>
                    <Row gutter={16} type="flex" justify="space-between" style={{marginTop: 16}}>
                        <PreviousButton url="/logement"/>
                        <Col xs={24} lg={4}>
                            <FormItem>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={hasErrors(getFieldsError()) || isCompany}
                                    style={{width: "100%"}}
                                >
                                    Suivant
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </div>
    );
};


export default Form.create()(PersonWrapper);