import React from 'react'
import { Row, Button, Col, Alert } from 'antd'

export default () => {

  const redirect = () => {
    window.top.location.href = '/souscription';
  };

  setTimeout(redirect, 10000);

  return (
    <div>
      <Row gutter={16} type="flex" justify="center" style={{ margin: "16px" }}>
        <Alert
          message="Votre paiement est enregistré. Merci d'avoir fait confiance à Altima"
          type="success"
          showIcon
        />
      </Row>

      <Row gutter={16} type="flex" justify="center" style={{ margin: "16px" }} >
        <Alert
          message="Dans quelques instants, vous allez être dirigé vers la page de confirmation. Si ce n'est pas le cas, cliquez sur le bouton Continuer"
          type="info"
          showIcon
        />
      </Row>

      <Row gutter={16} type="flex" justify="center" style={{ margin: "16px" }}>
        <Button
          type="primary"
          as='a'
          href='/souscription'
          target='_top'
          style={{ textAlign: "center" }}
        >Continuer</Button>
      </Row >
    </div >
  )
};