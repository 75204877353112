import instance from './services'
import data from '../donneesAssureur/assurreur.json'

import {
  resetDevis,
  saveDevisError,
  saveDevisFullfiled,
  saveDevisPending,
  storeDevisInformations
} from '../actions/devis'
import { setInfoLoiHamon } from '../actions/infoLoiHamon';



export function resetProposal() {
  return dispatch => {
    dispatch(resetDevis());
  }
}


const getAssureur = (code) => {
  const findedAssureur = data.find(assurrance => assurrance.codeGTA
    .includes(code))
  if (findedAssureur !== undefined) {
    return findedAssureur.nomAssureur
  } else {
    return "Assureur inconnu"
  }
}

export function createProposal(codeLogement, personne, informationAncienAssurreur, date) {

  let antecedent;
  if (informationAncienAssurreur !== undefined) {
    antecedent = {
      "actuellementAssure": true,
      "resilieInitiativeAssureur": false,
      "nomAssureurActuel": getAssureur(informationAncienAssurreur.codeGTA),
      "prenomNomSouscripteur": informationAncienAssurreur.prenomNom,
      "codeGtaAssureurActuel": informationAncienAssurreur.codeGTA,
      "contratAssurance": informationAncienAssurreur.contratAssurance,
      "adresseAssureur": informationAncienAssurreur.adresseAssureur,
      "villeAssureur": informationAncienAssurreur.villeAssureur,
      "assureurZipCode": informationAncienAssurreur.assureurZipCode
    };
  } else {
    antecedent = {
      "actuellementAssure": false,
    };
  }

  let contractant = {
    "title": personne.title,
    "lastname": personne.lastname,
    "firstname": personne.firstname,
    "birthdate": personne.birthdate,
    "birthCountry": personne.birthCountry,
    "birthCity": personne.birthCity,
    "birthZipCode": personne.birthZipCode,
    "nationality": personne.nationality,
    "mail": personne.mail,
    "phone": personne.phone,
    "profession": personne.profession,
    "dateEffective": date,
    "antecedent": antecedent
  };



  return dispatch => {
    const url = encodeURI("/api/v1/devis/logement/" + btoa(codeLogement));
    dispatch(storeDevisInformations({ codeLogement, contractant, date, antecedent }));
    dispatch(saveDevisPending());
    dispatch(setInfoLoiHamon(antecedent))
    instance.post(url, contractant)
      .then(res => res.data)
      .then(response => {
        dispatch(saveDevisFullfiled(response));
      })
      .catch(error => {
        dispatch(saveDevisError(error));
      }

      )
  }
}

export function updateProposal(idRefPers, entityIds, informationsBancaires) {
  return dispatch => {
    const url = encodeURI("/api/v1/personnes/" + idRefPers + "/devis/" + entityIds + "/informations-bancaires");
    dispatch(saveDevisPending())
    instance.post(url, informationsBancaires)
      .then(res => res.data)
      .then(response => {
        dispatch(saveDevisFullfiled(response));
      })
      .catch(error => dispatch(saveDevisError(error)))
  }
}
