import React from "react";
import { func, bool } from "prop-types";
import { Col, Form } from 'antd'
import Checkbox from 'antd/es/checkbox'

const FormItem = Form.Item;

function Consentements({ visible, getFieldDecorator, value, onChange }) {
  if (visible) {
    return (
      <Col span={24} style={{ marginTop: 16 }}>
        <FormItem style={{ lineHeight: '1.5' }} colon={false} required>
          {getFieldDecorator('consentement', {
            rules: [{
              required: true,
              message: 'Veuillez cocher la case de consentement'
            }],
          })(
            <Checkbox value={value} onChange={onChange}><span
              style={{ color: 'rgba(255,0,0,1)' }}>* </span>
              <b>Je demande expressément à ce que mon contrat prenne
                effet avant l’expiration du délai
                de 14 jours et renonce ainsi à me prévaloir de mon
                droit de renonciation, tel que décrit ci-dessus</b>
            </Checkbox>
          )}
        </FormItem>
      </Col>
    );
  } else {
    return <></>;
  }
}

Consentements.propTypes = {
  visible: bool.isRequired,
  value: bool.isRequired,
  onChange: func.isRequired,
  getFieldDecorator: func.isRequired
}

export default Consentements;