import { Col, Row, Upload, Button, message, Icon, Spin, Form } from 'antd';
import Modal from "antd/es/modal/Modal";
import { useHistory } from "react-router-dom";
import { verifToken, updateToken } from "../service/uploadService";
import React, { useEffect, useState } from 'react';

const Dragger = Upload.Dragger;

export default (props) => {

    let history = useHistory();

    const [upload, isUploaded] = useState(false);
    const [isPending, setIsPending] = useState(true)
    const [isError, setIsError] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState(true)
    const [uploading, isUploading] = useState(false);
    const [fileList, setFileList] = useState([]);
    // const [token, setToken] = useState("Test")

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    useEffect(() => {
        if (token !== undefined) {
            verifToken(token).then(response => {
                setIsError(false)
                setIsPending(false)
            }).catch(error => {
                setErrorMessage(error.response.data.message)
                setIsError(true)
                setIsPending(false)
            })
        }
    }, []);

    const onUploadChange = (info) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} Le document a été téléchargé avec succès.`);
            isUploaded(true);
        } else if (status === 'error') {
            // todo : remove!
            isUploaded(false)
            message.error(`${info.file.name} Le téléchargement du document a échoué.`);
        }
    };

    const errorModal = () => {
        Modal.error({
            icon: "",
            content: "Erreur lors de l'envoie du mandat",
            onOk() {
                dispatch(getHousingWrong())
            }
        });
    }

    const updateTokenOnClick = () => {
        updateToken(token).then(response => {
            history.push('/validationEnvoie')
        }).catch(error => {
            errorModal()
        })
    }

    const beforeUpload = (file) => {
        const isGoodFormat = file.type.match(/^(image\/jpg|image\/jpeg|image\/png|application\/pdf)$/) !== null;
        if (!isGoodFormat) {
            message.error('Seuls les formats jpg, jpeg, png ou pdf sont acceptés.');
        }
        const isLess10M = file.size / 1024 / 1024 < 10;
        if (!isLess10M) {
            message.error("Le fichier doit faire moins de 10MB.");
        }
        setFileList([...fileList, file])
        return isGoodFormat && isLess10M;
    };

    return (
        <div>
            <Spin spinning={isPending}>
                <Row style={{ marginTop: '16px' }}>
                    <div>
                        <Row gutter={16} type="flex" justify="center" style={{ marginTop: 32 }}>
                            <div>
                                <div className={"erreur-404"}>
                                    <div className={"image-upload"}></div>
                                </div>
                                {
                                    isError &&
                                    <div style={{ textAlign: 'center' }}>
                                        <span className={"text-error"}>Une erreur est surevenu lors du chargement de votre page, veuillez contacter votre conseiller</span>
                                        <h3>Message d'erreur : {errorMessage}</h3>
                                    </div>
                                }
                                {
                                    !isError &&
                                    <div style={{ textAlign: 'center' }}>
                                        <span className={"text-error"}>Je dépose mon mandat de prélèvement daté et signé.</span>
                                        <Col lg={24}>
                                            <h3>Téléchargement de votre Mandat</h3>
                                            <Dragger onChange={onUploadChange}
                                                name='file'
                                                beforeUpload={beforeUpload}
                                                action={'/api/v1/token/' + token + '/documents/mandat'}
                                                required
                                                accept='.jpg,.jpeg,.png,.pdf'>
                                                <p className="ant-upload-drag-icon">
                                                    <Icon type="inbox" />
                                                </p>
                                                <p className="ant-upload-text">Cliquez ou faites glisser votre document
                                                ici</p>
                                                <p className="ant-upload-hint">Formats acceptés: jpg, jpeg, png ou pdf</p>
                                                <p className="ant-upload-hint">Taille maximum du fichier: 10MB</p>
                                            </Dragger>
                                            <Button
                                                type="primary"
                                                style={{ width: '100%', marginTop: '15px' }}
                                                disabled={!upload}
                                                onClick={updateTokenOnClick}
                                            >
                                                Envoyer
                                            </Button>
                                        </Col>
                                    </div>
                                }
                            </div>
                        </Row>
                    </div>
                </Row>
            </Spin>
        </div >
    );
};