import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { getDevis, getPerson } from "../../selectors";
import { Row, Col, Modal, Button, Input, DatePicker, TimePicker, Checkbox } from 'antd'
import Form, { WrappedFormUtils } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import NumberFormat from "react-number-format"
import { requestCallBack } from "../../service/callBack"
import WebCallBackUnified from "../../models/domains/WebCallBackUnified"
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

moment.locale('fr');

const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const DEFAULT_HOURS = [
    { value: '09', label: '09h' },
    { value: '10', label: '10h' },
    { value: '11', label: '11h' },
    { value: '12', label: '12h' },
    { value: '13', label: '13h' },
    { value: '14', label: '14h' },
    { value: '15', label: '15h' },
    { value: '16', label: '16h' },
    { value: '17', label: '17h' },
    { value: '18', label: '18h' }
];

const DEFAULT_DAYS_OPEN = [
    { value: 1, label: 'lundi' },
    { value: 2, label: 'mardi' },
    { value: 3, label: 'mercredi' },
    { value: 4, label: 'jeudi' },
    { value: 5, label: 'vendredi' },
];


const webCallBack = (props) => {

    const altimaIsOpen = isHourOpen(moment().hour(), 265) && isDayOpen(moment())
    let [disabledHours, setDisabledHours] = useState(getDisabledHours());
    let [defaultSelectedTime, setDefaultSelectedTime] = useState();
    const { getFieldDecorator } = props.form;
    const { getFieldsError } = props.form;
    const [later, setLater] = useState(!altimaIsOpen)
    const [modalPhoneVisible, isModalPhoneVisible] = useState(false);
    const [modalConfirmVisible, isModalConfirmVisible] = useState(false);
    const personReducer = useSelector(state => getPerson(state));
    const devisReducer = useSelector(state => getDevis(state));

    function onDateChange(date) {
        if (date.isSame(moment(), 'day')) {
            setDisabledHours(getDisabledHoursToday());
        } else {
            setDisabledHours(getDisabledHours());
        }
    }

    function getDisabledHours() {
        const hours = Array.from({ length: 24 }, (v, k) => k)
        return hours.filter((it) => !isHourOpen(it, 265))
    }

    function getOpenHours() {
        const hours = Array.from({ length: 24 }, (v, k) => k)
        return hours.filter((it) => isHourOpen(it, 265))
    }

    function getDisabledHoursToday() {
        const openHours = getOpenHours()
        const hoursToDisable = openHours.filter((it) => it <= moment().hour())
        return getDisabledHours().concat(hoursToDisable)
    }

    function isHourOpen(currentTime, idx) {
        const openingHours = getOpeningHours(idx).map((item) => parseInt(item.value, 10))
        return openingHours.includes(currentTime)
    };

    function getOpeningHours(idx) {
        const configCallback = 'legacy_correction_bug';

        if (window[configCallback] === undefined) {
            return DEFAULT_HOURS
        }
        const hours = []
        const startingHour = Number(window[configCallback].horaires[idx][0].split(':')[0])
        const endingHour = Number(window[configCallback].horaires[idx][1].split(':')[0])

        for (let i = 0; i < (endingHour - startingHour) + 1; i++) {
            hours.push({ value: startingHour + i, label: `${startingHour + i}h` })
        }
        return hours;
    }

    function isDayOpen(currentTime) {
        const configCallback = 'legacy_correction_bug'
        const daysOpen = []
        if (window[configCallback] === undefined || window[configCallback].jours_ouverts) {
            DEFAULT_DAYS_OPEN
                .map((day) => day.value.toString())
                .map((value) => daysOpen.push(value))
            return (daysOpen.indexOf(currentTime.format('E').toString()) !== -1)
        } else {
            window[configCallback].jours_ouverts
                .map((day) => day.value.toString())
                .map((value) => daysOpen.push(value))
            return (daysOpen.indexOf(currentTime.format('E').toString()) !== -1)
        }
    }

    const onClick = () => {
        isModalPhoneVisible(true)
    }

    const handleOk = (e) => {
        e.preventDefault();

        props.form.validateFields((err, values) => {

            if (!err) {
                isModalPhoneVisible(false)

                const webCallBackUnified = new WebCallBackUnified()
                webCallBackUnified.description = personReducer.person.lastname +" "+personReducer.person.firstname
                webCallBackUnified.phone = props.form.getFieldValue("phone").replace(/\s+/g, '')
                webCallBackUnified.productId = 'MRHD'
                webCallBackUnified.serviceType = 'CAMPOUT'
                webCallBackUnified.personId = devisReducer.idRefPers
                if (later) {
                    const dateTime = props.form.getFieldValue("date").format('YYYY-MM-DD') + 'T'
                        + props.form.getFieldValue("time").format('HH:mm:ss')
                    webCallBackUnified.callDate = dateTime
                }
                requestCallBack(webCallBackUnified)
                isModalConfirmVisible(true)
            } else {
                return
            }
        })

    }

    const handleCancel = () => {
        isModalPhoneVisible(false)
    }

    const onLater = () => {
        setLater(!later, () => {
            if (props.form.isFieldsTouched(['date', 'time'])) {
                props.form.validateFields()
            }
        })
    }

    function handleConfirm() {
        isModalConfirmVisible(false)
    }

    function handleCancelConfirm() {
        isModalConfirmVisible(false)
    }

    const disabledDate = (current) => {
        return current && (current < moment().subtract(1, 'd') || moment().add(3, 'M') < current || current.day() === 0 || current.day() === 6)
    }

    function disabledHoursFunction() {
        return disabledHours
    }

    function disabledMinutes(selectedHour) {
        if (selectedHour == 18) {
            return [0o5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
        }
    }

    const handleChangeTime = (current) => {
        if(current.hour() == '18'){
            setDefaultSelectedTime(current.set({minute:0}));
        } 
    }

    const modalBody = (
        <Row>
            <Col span={24}>
                <FormItem label="Votre numéro de téléphone" hasFeedback={true} colon={false}>
                    {getFieldDecorator('phone', {
                        rules: [{
                            required: true,
                            message: 'Veuillez entrer votre numéro de téléphone'
                        }, {
                            max: 14,
                            message: 'Veuillez entrer un numéro de téléphone valide',
                            pattern: /0[12345679]( ?[0-9][0-9]){4}$/,
                        }],
                    })(
                        <NumberFormat format="## ## ## ## ##" placeholder="06 01 01 01 01" customInput={Input} />
                    )}
                </FormItem>
            </Col>
            {altimaIsOpen &&
                <Col span={24}>
                    <Checkbox onChange={onLater}>Me rappeler plus tard</Checkbox>
                </Col>
            }
            {!altimaIsOpen &&
                <Col span={24}>
                    Nos services sont actuellement fermés, veuillez nous indiquer une date et une heure de rappel.

            </Col>
            }
            {later && <div>
                <Col span={24}>
                    <FormItem label="Jour" hasFeedback={true}>
                        {getFieldDecorator('date', {
                            rules: [{
                                type: 'object',
                                required: later,
                                message: 'Veuillez entrer le jour souhaité pour votre rappel'
                            }],
                        })(
                            <DatePicker format="DD/MM/YYYY"
                                placeholder="21/05/2019"
                                disabledDate={disabledDate}
                                locale={locale}
                                onChange={onDateChange}
                                style={{ width: '100%' }} />
                        )}
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem label="Heure" hasFeedback={true}>
                        {getFieldDecorator('time', {
                            rules: [{
                                type: 'object',
                                required: later,
                                message: 'Veuillez entrer l\'heure souhaitée pour votre rappel'
                            }],
                        })(
                            <TimePicker format='HH:mm'
                                placeholder='09:00'
                                minuteStep={5}
                                disabledMinutes={disabledMinutes}
                                disabledHours={disabledHoursFunction}
                                onChange={handleChangeTime}
                                value={defaultSelectedTime}
                                style={{ width: '100%' }} />
                        )}
                    </FormItem>
                </Col>
            </div>

            }
        </Row>
    )

    return (

        <div>
            <div className="view-desktop" >
                <Button id="webCallBack"
                    icon="phone"
                    type="primary"
                    size="large"
                    style={{
                        background: '#c38282',
                        borderColor: '#c38282',
                        float: 'right',
                        position: 'fixed',
                        zIndex: 100,
                        right: 0,
                        top: 154,
                        height: 85,
                        borderRadius: '8, 0 , 0, 8',
                        display: 'block'
                    }}
                    onClick={onClick}>Me rappeler</Button>
                <Modal
                    visible={modalPhoneVisible}
                    title={'Des questions ? On vous rappelle'}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={<Row type="flex" justify="space-around">
                        <Col style={{ textAlign: 'center' }}>
                            <Button key="submit"
                                type="primary"
                                disabled={hasErrors(getFieldsError())}
                                onClick={handleOk} >
                                {later ? "Appelez moi plus tard" : "Appelez moi"}
                            </Button>
                        </Col>
                    </Row>

                    }>
                    {modalBody}
                </Modal>
                <Modal
                    visible={modalConfirmVisible}
                    title={'Votre rendez vous téléphonique'}
                    onOk={handleConfirm}
                    onCancel={handleCancelConfirm}
                    footer={<Button key="submit"
                        type="primary"
                        onClick={handleConfirm} >
                        Ok
                </Button>

                    }>
                    Un conseiller Altima va vous rappeler dans quelques minutes.
                </Modal>
            </div>
            <div className="view-mobile" style={{ display: 'none' }}>
                <Button id="webCallBack"
                    icon="phone"
                    type="primary"
                    size="large"
                    style={{
                        background: '#c38282',
                        borderColor: '#c38282',
                        float: "right",
                        position: "fixed",
                        zIndex: 100,
                        right: 0,
                        bottom: 0,
                        height: 70,
                        width: "100%",
                        display: "block",
                    }}
                    onClick={onClick}>Me rappeler</Button>
                <Modal
                    visible={modalPhoneVisible}
                    title={'Des questions ? On vous rappelle'}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={<Row type="flex" justify="space-around">
                        <Col style={{ textAlign: 'center' }}>
                            <Button key="submit"
                                type="primary"
                                disabled={hasErrors(getFieldsError())}
                                onClick={handleOk} >
                                {later ? "Appelez moi plus tard" : "Appelez moi"}
                            </Button>
                        </Col>
                    </Row>

                    }>
                    {modalBody}
                </Modal>
                <Modal
                    visible={modalConfirmVisible}
                    title={'Votre rendez vous téléphonique'}
                    onOk={handleConfirm}
                    onCancel={handleCancelConfirm}
                    footer={<Button key="submit"
                        type="primary"
                        onClick={handleConfirm} >
                        Ok
                </Button>

                    }>
                   { later? "Un conseiller Altima vous rappellera à la date demandée. ": "Un conseiller Altima va vous rappeler dans quelques minutes." }
                </Modal>
            </div>
        </div>

    );


}

export default Form.create()(webCallBack);
