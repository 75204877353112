import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect, useSelector } from 'react-redux';
import { Switch, Route, Router } from 'react-router-dom';
import { Layout, Menu, Row, Col, Popover, Icon } from 'antd';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from "redux";
import { syncHistoryWithStore } from "react-router-redux";
import thunk from "redux-thunk";
import Login from './pages/login';
import Logement from './pages/logementWrapper';
import PersonWrapper from './pages/personWrapper';
import DevisWrapper from './pages/devisWrapper';
import Resume from './pages/resume';
import Advice from './components/form/adviceForm';
import Bank from './pages/bank';
import Payment from './pages/payment';
import Souscription from './pages/souscription';
import Erreur404 from './pages/Erreur404';
import HowItWork from './pages/info/howItWork';
import Garanties from './pages/info/garanties';
import Faq from './pages/info/faq';
import UploadMandat from './pages/UploadMandat';
import ValidationEnvoie from './pages/validationEnvoie';
import Success from './components/iframe/success';
import Failure from './components/iframe/failure';
import { store } from './store';
import 'antd/dist/antd.less';
import './index.css';
import { LogoAltimaIconOverzoom } from "./assets/icons";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const finalStore = createStore(
  store,
  composeEnhancers(applyMiddleware(thunk))
);
const history = syncHistoryWithStore(
  createBrowserHistory(), finalStore
);
const { Header, Content, Footer } = Layout;


const Application = (props) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const housingReducer = useSelector(state => state.housingReducer);

  function handleMenuVisibility(visible) {
    setMenuVisible(visible);
  };

  return (
    <Router history={history}>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ background: '#fff' }} >
          <Row gutter={16} type='flex' justify='space-between'>
            <div style={{ flex: "1", maxHeight: "64px", alignSelf: "center" }}>
              {
                window.devicePixelRatio > 1 ?
                  <LogoAltimaIconOverzoom className="logoAltima-retina-overzoom" />
                  :
                  <img id="logo" src="/logo_altima_maif_overzoom.png"
                    style={{
                      display: 'block',
                      maxHeight: '64px',
                      marginLeft: '24px',
                      width: 'auto',
                      height: 'auto'
                    }}
                    alt="Logo" />
              }
            </div>
            <div className="view-desktop">
              <div style={{ paddingRight: '8px' }}>
                {`${window.location.pathname}` !== "/commentcamarche"
                  && !`${window.location.pathname}`.includes('/garanties')
                  && !`${window.location.pathname}`.includes('/faq')
                  &&

                  <Menu
                    mode="horizontal"
                    style={{ lineHeight: '64px' }}
                    selectable={false}
                  >
                    <Menu.Item key="1">
                      <a style={{ display: "table-cell", textAlign: 'center' }} target="_blank"
                        href={`${window.location.protocol}//${window.location.host}/commentcamarche`}>Comment
                        ça marche</a></Menu.Item>
                    {housingReducer.isAuthenticated &&
                      <Menu.Item key="2" >
                        <a style={{ display: "table-cell", textAlign: 'center' }} target="_blank"
                          href={`${window.location.protocol}//${window.location.host}/garanties/?login=${housingReducer.house.housingCode}`}>Nos garanties</a></Menu.Item>}
                    {housingReducer.isAuthenticated &&
                      <Menu.Item key="3" ><a style={{ display: "table-cell", textAlign: 'center' }} target="_blank"
                        href={`${window.location.protocol}//${window.location.host}/faq/?login=${housingReducer.house.housingCode}`}>Questions fréquentes</a></Menu.Item>}

                  </Menu>

                }
              </div>
            </div>
            <div className="view-mobile" style={{ display: 'none' }}>
              <div style={{ paddingRight: '8px' }}>
                {`${window.location.pathname}` !== "/commentcamarche"
                  && !`${window.location.pathname}`.includes('/garanties')
                  && !`${window.location.pathname}`.includes('/faq')
                  &&

                  <Popover
                    content={<Menu
                      mode="horizontal"
                      style={{ lineHeight: '64px' }}
                      selectable={false}
                    >
                      <Menu.Item key="1">
                        <a style={{ display: "table-cell", textAlign: 'center' }} target="_blank"
                          href={`${window.location.protocol}//${window.location.host}/commentcamarche`}>Comment
                          ça marche</a></Menu.Item>
                      {housingReducer.isAuthenticated &&
                        <Menu.Item key="2" >
                          <a style={{ display: "table-cell", textAlign: 'center' }} target="_blank"
                            href={`${window.location.protocol}//${window.location.host}/garanties/?login=${housingReducer.house.housingCode}`}>Nos garanties</a></Menu.Item>}
                      {housingReducer.isAuthenticated &&
                        <Menu.Item key="3" ><a style={{ display: "table-cell", textAlign: 'center' }} target="_blank"
                          href={`${window.location.protocol}//${window.location.host}/faq/?login=${housingReducer.house.housingCode}`}>Questions fréquentes</a></Menu.Item>}

                    </Menu>
                    }
                    trigger='click'
                    placement='bottom'
                    visible={menuVisible}
                    onVisibleChange={e => handleMenuVisibility(e)}
                  >
                    <Icon
                      className='iconHamburger'
                      type='menu'
                    />
                  </Popover>
                }
              </div>
            </div>
          </Row>
        </Header>
        <Content className="content" style={{ marginTop: 16 }}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/logement" exact component={Logement} />
            <Route path="/identite" exact component={PersonWrapper} />
            <Route path="/devis" exact component={DevisWrapper} />
            <Route path="/resume" exact component={Resume} />
            <Route path="/conseil" exact component={Advice} />
            <Route path="/renseignement-bancaires" exact component={Bank} />
            <Route path="/paiement" exact component={Payment} />
            <Route path="/souscription" exact component={Souscription} />
            <Route path="/commentcamarche" exact component={HowItWork} />
            <Route path="/garanties" exact component={Garanties} />
            <Route path="/faq" exact component={Faq} />
            <Route path="/UploadMandat" exact component={UploadMandat} />
            <Route path="/validationEnvoie" exact component={ValidationEnvoie} />
            <Route path="/invoices/success" component={Success} />
            <Route path="/invoices/failure" component={Failure} />
            <Route component={Erreur404} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Row type="flex" justify="center">
            <Col xs={24} lg={4} style={{ textAlign: 'center' }}>
              <a target="_blank" href="https://www.altima-assurances.fr/altima/fr/mentions-l%C3%A9gales?p=btob">
                Mentions légales</a>
            </Col>
            <Col xs={24} lg={4} style={{ textAlign: 'center' }}>
              <a style={{ textAlign: 'center' }} target="_blank"
                href="https://www.altima-assurances.fr/altima/fr/donn%C3%A9es-personnelles?p=btob">Données personnelles</a>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Router>
  );
}

const LinkedApplication = connect()(Application);

export const App = (id) => {
  ReactDOM.render(
    <Provider store={finalStore}>
      <LinkedApplication />
    </Provider>, document.getElementById(id))
}
