
import instance from './services'

export function postConseil(idPers, entityIds, file) {

        const url = encodeURI(`/api/v1/personnes/${idPers}/devis/${entityIds}/documents/conseil`);
        var bodyFormData = new FormData();
        bodyFormData.append('file', file);
        let axiosConfig = {
                headers: {
                        'Content-Type': 'multipart/form-data',
                }
        };

        instance.post(url, bodyFormData, axiosConfig)
                .then(res => res.data)
                .then(resp => {
                        resp
                })
                .catch(error => console.error(error))


}