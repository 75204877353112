import { Card, Col, Divider, Row, Collapse, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { getConfig } from "../../service/configService";
import { useDispatch, useSelector } from "react-redux";
import { getHousing } from "../../service/houseService";
import { getGaranties } from "../../service/contentService";

const Panel = Collapse.Panel;

export default (props) => {

    const [garanties, setGaranties] = useState([]);
    const [linkToDoc, setLinkToDoc] = useState("");

    const dispatch = useDispatch();
    const housingReducer = useSelector(state => state.housingReducer);

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const login = params.get("login");

    useEffect(() => {
        if (login !== null) {
            dispatch(getHousing(login))
            getGaranties().then(response => {
                if (response) {
                    setGaranties(response);
                }
            });
        }
    }, []);

    useEffect(() => {
        getConfig().then(res =>
            setLinkToDoc(res.DOC_LOC_URL));
    }, []);

    if (login !== null) {
        if (housingReducer.error !== undefined) {
            return (<Redirect to='/' />);
        }
    }

    const openIpid = () => {
        window.open(linkToDoc + "IPID.pdf",
            "_blank");
    };

    const openFicheInfo = () => {
        window.open(linkToDoc + "fiche_information.pdf");
    };

    const openCG = () => {
        window.open(linkToDoc + "Conditions_Generales.pdf",
            "_blank");
    };

    return (
        <div>
            <div className='view-desktop'>
                <Divider><h2 style={{ fontSize: "2vw" }} className="step_title">Tableaux des limites de garanties</h2></Divider>
            </div>
            <div className="view-mobile" style={{ display: "none" }}>
                <Divider><h3 style={{ fontSize: "4vw", margin: 0 }} className="step_title">Tableaux des limites de garanties</h3></Divider>
            </div>
            {
                garanties.map(
                    (garantie, index) => <Row gutter={16} key={index} type="flex" justify="center" >
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Card title={garantie.titre}>
                                <div dangerouslySetInnerHTML={{ __html: garantie.descriptionTypeAssurance.value }} />
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Card title="Limites de garanties">
                                <div dangerouslySetInnerHTML={{ __html: garantie.descriptionLimitesGaranties.value }} />
                            </Card>
                        </Col>
                    </Row>
                )
            }
            <div className='view-desktop'>
                <Divider><h2 style={{ fontSize: "2vw" }} className="step_title">Conditions Générales et Documents d'informations</h2></Divider>
            </div>
            <div className="view-mobile" style={{ display: "none" }}>
                <Divider><h3 style={{ fontSize: "4vw", margin: 0 }} className="step_title">Conditions Générales et Documents d'informations</h3></Divider>
            </div>
            <Row>
                <Col span={24}>
                    <Collapse bordered={false} >
                        <Panel header="Document d'information standardisé" key="2" >
                            <Row type="flex" justify="space-between">
                                <Col span={20}>Document synthétique de l'offre</Col>
                                <Col span={4}>
                                    <Button
                                        style={{ textAlign: "center" }}
                                        type="primary"
                                        onClick={openIpid}
                                    >
                                        Voir
                                    </Button>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header="La fiche d'information" key="3">
                            <Row type="flex" justify="space-between">
                                <Col span={20}>Document d'information et de conseil sur le coût, les garanties et les modalités d'adhésion du contrat.</Col>
                                <Col span={4}>
                                    <Button
                                        style={{ textAlign: "center" }}
                                        type="primary"
                                        onClick={openFicheInfo}
                                    >
                                        Voir
                                    </Button>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header="Les conditions générales" key="4">
                            <Row type="flex" justify="space-between">
                                <Col span={20}>Les conditions générales</Col>
                                <Col span={4}>
                                    <Button
                                        style={{ textAlign: "center" }}
                                        type="primary"
                                        onClick={openCG}
                                    >
                                        Voir
                                    </Button>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row id="garanties_cg">
                <Col span={24}>
                    Les garanties accordées sont définies aux Conditions Générales ASSURANCE HABITATION LOCATAIRE ALTIMA réf 202002_378.
                </Col>
            </Row>
        </div>
    );
};