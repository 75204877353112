import React from 'react'
import { Row, Button, Alert, Col } from 'antd'


export default () => {
  const redirect = () => {
    window.top.location.href = '/renseignement-bancaires';
  };

  setTimeout(redirect, 10000);

  return (
    <div>
      <Row gutter={16} type="flex" justify="center" style={{ margin: "16px" }}>
        <Alert
          message="Votre paiement a échoué. Veuillez contacter un de nos gestionnaires pour finaliser votre souscription."
          type="error"
          showIcon
        />
      </Row>

      <Row gutter={16} type="flex" justify="center" style={{ margin: "16px" }}>
        <Alert
          message="Dans quelques instants, vous allez être dirigé vers la page d'accueil. Si ce n'est pas le cas, cliquez sur le bouton Continuer"
          type="info"
          showIcon
        />
      </Row>

      <Row gutter={16} type="flex" justify="center" style={{ margin: "16px" }}>
        <Button
          type="primary"
          as='a'
          href='/'
          target='_top'
          style={{ textAlign: "center" }}
        >Accueil</Button>
      </Row >
    </div>
  )
};