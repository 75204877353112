import { GET_HOUSING_ASK, GET_HOUSING_ERROR, GET_HOUSING_FULLFILED, GET_HOUSING_WRONG } from '../reducers/type';



export const getHousingAsk = () => ({
  type: GET_HOUSING_ASK
});

export const getHousingFullfiled = (oneHouse) => ({
  type: GET_HOUSING_FULLFILED,
  payload : oneHouse
});

export const getHousingError = (error) => ({
  type: GET_HOUSING_ERROR,
  payload : error
});

export const getHousingWrong = () => ({
  type: GET_HOUSING_WRONG
});






