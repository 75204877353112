import React from "react";

export const LogoAltimaIcon = (props) => (
  <svg id="LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.89 89.65" {...props}>
    <defs>
      <style>{".cls-1{fill:#ffba00;}.cls-2{fill:#14a6ff;}.cls-3{fill:#fc0d1a;}"}</style>
    </defs>
    <g id="CMJN">
      <rect x="43.07" y="40.31" width="1.74" height="9.27" />
      <path
        d="M60.31,49.58V44.43c0-.79-.1-2.78-2.19-2.78s-2.19,2-2.21,2.76v5.17H54.17V44.43a3.15,3.15,0,0,0-.39-1.91,1.91,1.91,0,0,0-1.72-.87c-2.29,0-2.29,2.08-2.29,2.76v5.17H48V40.31h1.6v1h0a3,3,0,0,1,2.66-1.26,3.19,3.19,0,0,1,2.88,1.56,3.19,3.19,0,0,1,3.06-1.56,3.54,3.54,0,0,1,3.26,1.67A4.58,4.58,0,0,1,62.05,44v5.59Z" />
      <path
        d="M20.63,41.73a3.17,3.17,0,0,0,0,6.33,3.16,3.16,0,0,0,3.18-3.14,3.08,3.08,0,0,0-3.17-3.19m3.05,7.63V48h0a3.54,3.54,0,0,1-3.1,1.61,4.7,4.7,0,1,1,0-9.4,3.66,3.66,0,0,1,3.1,1.5h0V40.44H25.4v8.92Z" />
      <polygon points="28.52 40.19 30.26 40.19 30.26 47.78 33.93 47.78 33.93 49.36 28.52 49.36 28.52 40.19" />
      <polygon
        points="36.67 41.78 34.34 41.78 34.34 40.19 40.76 40.19 40.76 41.78 38.41 41.78 38.41 49.36 36.67 49.36 36.67 41.78" />
      <path
        d="M69.13,41.73a3.16,3.16,0,1,0,3.17,3.19,3.08,3.08,0,0,0-3.17-3.19m3.13,7.63V48h0a3.5,3.5,0,0,1-3.1,1.61,4.69,4.69,0,1,1,0-9.38,3.63,3.63,0,0,1,3.1,1.5h0V40.44H74v8.92Z" />
    </g>
    <path className="cls-1"
      d="M10.2,44.82A34.68,34.68,0,0,1,44.84,10.19v-.8a35.44,35.44,0,1,0,0,70.87v-.8A34.68,34.68,0,0,1,10.2,44.82Z" />
    <path className="cls-2" d="M44.84,9.39v.8a34.64,34.64,0,0,1,0,69.27v.8a35.44,35.44,0,0,0,0-70.87Z" />
    <rect className="cls-3" x="163.1" y="46.67" width="2.1" height="7.23" />
    <path className="cls-3" d="M159,48.74l.63,2.3H158.4Zm-1.13-2.07-2.61,7.24h2.35l.38-1.2h2l.39,1.2h2.34l-2.6-7.24Z" />
    <polygon className="cls-3"
      points="152.52 46.67 150.89 50.2 149.26 46.67 146.75 46.67 146.75 53.91 148.8 53.91 148.8 49.83 150.19 52.81 151.53 52.81 152.92 49.83 152.92 53.91 154.96 53.91 154.96 46.67 152.52 46.67" />
    <polygon className="cls-3"
      points="166 46.67 166 53.91 168.1 53.91 168.1 51.13 170.75 51.13 171.03 49.41 168.1 49.41 168.1 48.39 171.22 48.39 171.49 46.67 166 46.67" />
    <path
      d="M92.48,43.14c-2,0-2.76-1.28-2.76-2.93v-4.4h.89v4.4c0,1.32.44,2.13,1.87,2.13s1.86-.81,1.86-2.13V35.83l.89-.11v4.49c0,1.65-.73,2.93-2.75,2.93" />
    <polygon
      points="96.81 43.04 96.81 35.8 97.72 35.8 101.41 41.59 101.41 35.84 102.27 35.73 102.27 43.04 101.36 43.04 97.67 37.25 97.67 43.04 96.81 43.04" />
    <polygon
      points="103.97 35.81 108.45 35.81 108.45 36.6 104.86 36.6 104.86 38.92 107.88 38.92 107.88 39.71 104.86 39.71 104.86 42.24 108.61 42.24 108.71 43.04 103.97 43.04 103.97 35.81" />
    <path
      d="M113.93,43.14a4.48,4.48,0,0,1-2.64-.74l.38-.74a3.84,3.84,0,0,0,2.27.68c1.09,0,1.62-.33,1.62-1.2,0-.71-.35-1-1.31-1.21l-.81-.17c-1.47-.31-2-1-2-2.06,0-1.36.85-2,2.39-2a4.36,4.36,0,0,1,2.47.72l-.38.72a3.5,3.5,0,0,0-2.09-.64c-1,0-1.52.37-1.52,1.16s.32,1,1.4,1.24l.81.17c1.34.28,1.92.88,1.92,2,0,1.4-.86,2-2.5,2" />
    <path
      d="M122.6,39.42c0-1.73-.51-2.92-2.16-2.92s-2.16,1.19-2.16,2.92.51,2.93,2.16,2.93,2.16-1.2,2.16-2.93m-5.27,0c0-2,.83-3.71,3.11-3.71s3.1,1.67,3.1,3.71-.82,3.72-3.1,3.72-3.11-1.67-3.11-3.72" />
    <path
      d="M129.84,42.52a4.21,4.21,0,0,1-2.26.62c-2.28,0-3-1.61-3-3.72s.76-3.71,3-3.71a4,4,0,0,1,2.16.59l-.38.68a3.48,3.48,0,0,0-1.78-.48c-1.65,0-2.1,1.12-2.1,2.92s.45,2.93,2.1,2.93a3.66,3.66,0,0,0,1.9-.54Z" />
    <polygon points="130.92 35.83 130.92 43.04 131.81 43.04 131.81 35.72 130.92 35.83" />
    <path d="M133.52,35.81V43h4.73l-.1-.8H134.4V39.71h3v-.79h-3V36.6H138v-.79Zm2.93-2.51-1.37,1.64h.83l1.16-1.07Z" />
    <polygon
      points="141.09 43.04 141.09 36.6 138.64 36.6 138.64 35.8 144.42 35.8 144.32 36.6 141.97 36.6 141.97 43.04 141.09 43.04" />
    <path d="M145.43,35.81V43h4.73l-.1-.8h-3.75V39.71h3v-.79h-3V36.6h3.59v-.79Zm2.93-2.51L147,34.94h.83L149,33.87Z" />
    <path
      d="M90.57,47.47v5.64h1.64c1.71,0,2.23-1.15,2.23-2.82s-.52-2.82-2.23-2.82Zm-.88,6.44V46.67h2.52c2.32,0,3.17,1.63,3.17,3.62s-.85,3.62-3.17,3.62Z" />
    <path
      d="M99.34,54c-2,0-2.75-1.28-2.75-2.94v-4.4h.88v4.4c0,1.33.44,2.14,1.87,2.14s1.87-.81,1.87-2.14V46.69l.88-.11v4.49c0,1.66-.72,2.94-2.75,2.94" />
    <path
      d="M108.43,54c-2.33,0-3.1-1.61-3.1-3.72s.77-3.72,3.11-3.72a4.12,4.12,0,0,1,2.25.63l-.38.7a3.57,3.57,0,0,0-1.87-.53c-1.7,0-2.17,1.12-2.17,2.92s.47,2.92,2.16,2.92a4.15,4.15,0,0,0,1.5-.28V50.51l.88-.11v2.93a4.3,4.3,0,0,1-2.38.68" />
    <path
      d="M113.07,47.47v2.71h1.8c1.07,0,1.4-.56,1.4-1.36s-.33-1.35-1.4-1.35Zm-.88,6.44V46.67h2.68c1.72,0,2.35,1,2.35,2.15a1.91,1.91,0,0,1-1.61,2.07l1.9,3h-1.07L114.62,51h-1.55v2.95Z" />
    <path
      d="M123.56,50.29c0-1.73-.52-2.92-2.16-2.92s-2.16,1.19-2.16,2.92.51,2.92,2.16,2.92,2.16-1.19,2.16-2.92m-5.27,0c0-2,.83-3.72,3.11-3.72s3.1,1.68,3.1,3.72S123.68,54,121.4,54s-3.11-1.67-3.11-3.72" />
    <path
      d="M128.47,54c-2,0-2.76-1.28-2.76-2.94v-4.4h.89v4.4c0,1.33.44,2.14,1.87,2.14s1.87-.81,1.87-2.14V46.69l.88-.11v4.49c0,1.66-.72,2.94-2.75,2.94" />
    <path
      d="M133.69,47.47v3.2h1.65c1.17,0,1.53-.66,1.53-1.61s-.36-1.59-1.53-1.59Zm-.88,6.44V46.67h2.53a2.4,2.4,0,1,1,0,4.8h-1.65v2.44Z" />
    <polygon
      points="139.03 46.67 139.03 53.91 143.76 53.91 143.66 53.11 139.92 53.11 139.92 50.58 142.94 50.58 142.94 49.79 139.92 49.79 139.92 47.47 143.5 47.47 143.5 46.67 139.03 46.67" />
  </svg>
);

export const LogoAltimaIconOverzoom = (props) => (
  <svg id="LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.89 40.71" {...props}>
    <defs>
      <style>{".cls-1{fill:#ffba00;}.cls-2{fill:#FFBA00;}.cls-3{fill:#fc0d1a;}"}</style>
    </defs>
    <path className="cls-1"
      d="M10.2,20.29A34.48,34.48,0,0,1,12,9.39h-.85a35.39,35.39,0,0,0,0,21.92H12A34.64,34.64,0,0,1,10.2,20.29Z" />
    <path className="cls-2"
      d="M80.27,20.29a35.63,35.63,0,0,0-1.71-10.9H77.7a34.45,34.45,0,0,1,0,21.92h.85A35.23,35.23,0,0,0,80.27,20.29Z" />
    <g id="CMJN">
      <rect x="43.07" y="15.84" width="1.74" height="9.27" />
      <path
        d="M60.31,25.11V20c0-.79-.1-2.78-2.19-2.78s-2.19,2-2.21,2.76v5.17H54.17V20a3.15,3.15,0,0,0-.39-1.91,1.91,1.91,0,0,0-1.72-.87c-2.29,0-2.29,2.07-2.29,2.76v5.17H48V15.84h1.6v1h0a3,3,0,0,1,2.66-1.25,3.17,3.17,0,0,1,2.88,1.56,3.19,3.19,0,0,1,3.06-1.56,3.52,3.52,0,0,1,3.26,1.67,4.55,4.55,0,0,1,.52,2.26v5.59Z" />
      <path
        d="M20.63,17.25a3.17,3.17,0,1,0,3.17,3.19,3.08,3.08,0,0,0-3.17-3.19m3.05,7.64V23.51h0a3.54,3.54,0,0,1-3.1,1.61,4.7,4.7,0,1,1,0-9.4,3.66,3.66,0,0,1,3.1,1.5h0V16H25.4v8.93Z" />
      <polygon points="28.52 15.72 30.26 15.72 30.26 23.3 33.93 23.3 33.93 24.89 28.52 24.89 28.52 15.72" />
      <polygon
        points="36.67 17.31 34.34 17.31 34.34 15.72 40.76 15.72 40.76 17.31 38.41 17.31 38.41 24.89 36.67 24.89 36.67 17.31" />
      <path
        d="M69.13,17.26a3.16,3.16,0,1,0,3.17,3.18,3.08,3.08,0,0,0-3.17-3.18m3.13,7.63V23.5h0a3.5,3.5,0,0,1-3.1,1.61,4.69,4.69,0,1,1,0-9.38,3.65,3.65,0,0,1,3.1,1.49h0V16H74v8.92Z" />
    </g>
    <rect className="cls-3" x="163.1" y="22.08" width="2.1" height="7.23" />
    <path className="cls-3"
      d="M159,24.15l.63,2.3H158.4Zm-1.13-2.07-2.61,7.23h2.35l.38-1.19h2l.39,1.19h2.34l-2.6-7.23Z" />
    <polygon className="cls-3"
      points="152.52 22.08 150.89 25.61 149.26 22.08 146.75 22.08 146.75 29.31 148.8 29.31 148.8 25.24 150.19 28.22 151.53 28.22 152.92 25.24 152.92 29.31 154.96 29.31 154.96 22.08 152.52 22.08" />
    <polygon className="cls-3"
      points="166 22.08 166 29.31 168.1 29.31 168.1 26.54 170.75 26.54 171.03 24.82 168.1 24.82 168.1 23.8 171.22 23.8 171.49 22.08 166 22.08" />
    <path
      d="M92.48,18.55c-2,0-2.76-1.28-2.76-2.94v-4.4h.89v4.4c0,1.33.44,2.14,1.87,2.14s1.86-.81,1.86-2.14V11.23l.89-.11v4.49c0,1.66-.73,2.94-2.75,2.94" />
    <polygon
      points="96.81 18.45 96.81 11.21 97.72 11.21 101.41 17 101.41 11.24 102.27 11.13 102.27 18.45 101.36 18.45 97.67 12.66 97.67 18.45 96.81 18.45" />
    <polygon
      points="103.97 11.21 108.45 11.21 108.45 12.01 104.86 12.01 104.86 14.33 107.88 14.33 107.88 15.12 104.86 15.12 104.86 17.65 108.61 17.65 108.71 18.45 103.97 18.45 103.97 11.21" />
    <path
      d="M113.93,18.55a4.41,4.41,0,0,1-2.64-.75l.38-.73a3.84,3.84,0,0,0,2.27.68c1.09,0,1.62-.33,1.62-1.2,0-.72-.35-1-1.31-1.21l-.81-.17c-1.47-.31-2-1-2-2.06,0-1.36.85-2,2.39-2a4.29,4.29,0,0,1,2.47.72l-.38.72a3.5,3.5,0,0,0-2.09-.64c-1,0-1.52.37-1.52,1.16,0,.63.32,1,1.4,1.24l.81.17c1.34.28,1.92.88,1.92,2,0,1.39-.86,2-2.5,2" />
    <path
      d="M122.6,14.83c0-1.73-.51-2.92-2.16-2.92s-2.16,1.19-2.16,2.92.51,2.92,2.16,2.92,2.16-1.19,2.16-2.92m-5.27,0c0-2,.83-3.72,3.11-3.72s3.1,1.68,3.1,3.72-.82,3.72-3.1,3.72-3.11-1.67-3.11-3.72" />
    <path
      d="M129.84,17.92a4.13,4.13,0,0,1-2.26.63c-2.28,0-3-1.61-3-3.72s.76-3.72,3-3.72a4,4,0,0,1,2.16.6l-.38.68a3.48,3.48,0,0,0-1.78-.48c-1.65,0-2.1,1.12-2.1,2.92s.45,2.92,2.1,2.92a3.65,3.65,0,0,0,1.9-.53Z" />
    <polygon points="130.92 11.23 130.92 18.45 131.81 18.45 131.81 11.13 130.92 11.23" />
    <path d="M133.52,11.22v7.23h4.73l-.1-.8H134.4V15.12h3v-.79h-3V12H138v-.79Zm2.93-2.51-1.37,1.64h.83l1.16-1.07Z" />
    <polygon
      points="141.09 18.45 141.09 12.01 138.64 12.01 138.64 11.21 144.42 11.21 144.32 12.01 141.97 12.01 141.97 18.45 141.09 18.45" />
    <path d="M145.43,11.22v7.23h4.73l-.1-.8h-3.75V15.12h3v-.79h-3V12h3.59v-.79Zm2.93-2.51L147,10.35h.83L149,9.28Z" />
    <path
      d="M90.57,22.88v5.64h1.64c1.71,0,2.23-1.15,2.23-2.82s-.52-2.82-2.23-2.82Zm-.88,6.43V22.08h2.52c2.32,0,3.17,1.63,3.17,3.62s-.85,3.61-3.17,3.61Z" />
    <path
      d="M99.34,29.42c-2,0-2.75-1.28-2.75-2.94v-4.4h.88v4.4c0,1.33.44,2.14,1.87,2.14s1.87-.81,1.87-2.14V22.1l.88-.11v4.49c0,1.66-.72,2.94-2.75,2.94" />
    <path
      d="M108.43,29.42c-2.33,0-3.1-1.61-3.1-3.72S106.1,22,108.44,22a4.2,4.2,0,0,1,2.25.62l-.38.71a3.57,3.57,0,0,0-1.87-.53c-1.7,0-2.17,1.12-2.17,2.92s.47,2.92,2.16,2.92a4.15,4.15,0,0,0,1.5-.28V25.92l.88-.11v2.93a4.39,4.39,0,0,1-2.38.68" />
    <path
      d="M113.07,22.88v2.71h1.8c1.07,0,1.4-.56,1.4-1.36s-.33-1.35-1.4-1.35Zm-.88,6.43V22.08h2.68c1.72,0,2.35,1,2.35,2.15a1.91,1.91,0,0,1-1.61,2.07l1.9,3h-1.07l-1.82-2.94h-1.55v2.94Z" />
    <path
      d="M123.56,25.7c0-1.73-.52-2.93-2.16-2.93s-2.16,1.2-2.16,2.93.51,2.92,2.16,2.92,2.16-1.19,2.16-2.92m-5.27,0c0-2,.83-3.72,3.11-3.72s3.1,1.68,3.1,3.72-.82,3.71-3.1,3.71-3.11-1.66-3.11-3.71" />
    <path
      d="M128.47,29.42c-2,0-2.76-1.28-2.76-2.94v-4.4h.89v4.4c0,1.33.44,2.14,1.87,2.14s1.87-.81,1.87-2.14V22.1l.88-.11v4.49c0,1.66-.72,2.94-2.75,2.94" />
    <path
      d="M133.69,22.88v3.2h1.65c1.17,0,1.53-.66,1.53-1.61s-.36-1.59-1.53-1.59Zm-.88,6.43V22.08h2.53a2.2,2.2,0,0,1,2.48,2.39,2.2,2.2,0,0,1-2.48,2.4h-1.65v2.44Z" />
    <polygon
      points="139.03 22.08 139.03 29.31 143.76 29.31 143.66 28.52 139.92 28.52 139.92 25.99 142.94 25.99 142.94 25.2 139.92 25.2 139.92 22.88 143.5 22.88 143.5 22.08 139.03 22.08" />
  </svg>
);