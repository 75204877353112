import * as React from 'react';
import { Row, Col, Divider, Card, Alert, Button, Form } from "antd";
import Radio from "antd/lib/radio/radio";
import RadioGroup from "antd/lib/radio/group";
import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Step from "../step";
import html2canvas from "html2canvas"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { unescape } from "querystring";
import { postConseil } from "../../service/documentService";
import WebCallBack from "../webCallBack/webCallBack"

const FormItem = Form.Item;




function captureAndNext(idpers, entityIds) {
    const input = document.body;
    html2canvas(input)
        .then((canvas) => {
            const capture64 = canvas.toDataURL('image/png');
            const capture = dataURItoBlob(capture64);
            postConseil(idpers, entityIds, capture)
        })
}

function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1])
    }
    else {
        byteString = unescape(dataURI.split(',')[1])
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
}


const Advice = (props) => {

    const [isUsuableObjectsValue, setIsUsuableObjectsValue] = useState(undefined);
    const [isValuableObjectsValue, setIsValuableObjectsValue] = useState(undefined);
    const [isMultipleItemsValue, setIsMultipleItemsValue] = useState(undefined);
    const [isDisableButton, setIsDisableButton] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const capitalReducer = useSelector(state => state.capitauxReducer);
    const [messageType, setMessageType] = useState("");
    const devisReducer = useSelector(state => state.devisReducer);
    const { getFieldDecorator } = props.form;
    const completeId = devisReducer.idDevis + "-" + devisReducer.idMaj

    const setMessage = () => {
        if (isUsuableObjectsValue !== undefined && isValuableObjectsValue !== undefined
            && isMultipleItemsValue !== undefined) {
            setIsDisableButton(false);
            if (isUsuableObjectsValue && isValuableObjectsValue && !isMultipleItemsValue) {
                setErrorMessage("Notre contrat correspond à vos besoins et exigences en matière d'assurance habitation.");
                setMessageType("success");
            } else if (!isUsuableObjectsValue && !isValuableObjectsValue && isMultipleItemsValue) {
                setErrorMessage("Notre contrat ne correspond pas à vos besoins et exigences en matière d'assurance habitation." +
                    "Si vous souscrivez, vous acceptez que les garanties ne répondent pas à l'ensemble de vos attentes.");
                setMessageType("error");
            } else {
                setErrorMessage("Notre contrat ne correspond pas totalement à vos besoins et exigences, nous attirons " +
                    "votre attention sur le fait que le risque que vous avez déclaré ne sera pas entièrement couvert." +
                    " Si vous souscrivez, vous acceptez que les garanties ne répondent pas à l'ensemble de vos attentes.");
                setMessageType("warning");
            }
        }
    };

    const onChangeUsuable = (e) => {
        setIsUsuableObjectsValue(e.target.value);
    };

    const onChangeValuable = (e) => {
        setIsValuableObjectsValue(e.target.value);
    };

    const onChangeMultiple = (e) => {
        setIsMultipleItemsValue(e.target.value);
    };

    useEffect(() => {
        setMessage();
    })

    if (capitalReducer.capitaux === undefined) {
        return (<Redirect to='/' />)
    }

    const onSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div id="divToPrint">
            <Step step={4} />
            <Row style={{ marginTop: '16px' }}>
                <Row type="flex" justify="end">
                    <Col span={2} style={{ marginRight: -6, marginTop: 32 }}>
                        <WebCallBack />
                    </Col>
                </Row>
                <Form onSubmit={e => onSubmit(e)}>
                    <div className='view-desktop'>
                        <Divider><h2 style={{ fontSize: '2vw' }} className="step_title">Conseil</h2></Divider>
                    </div>
                    <div className="view-mobile" style={{ display: 'none' }}>
                        <Divider><h3 style={{ fontSize: '4vw', margin: 0 }} className="step_title">Conseil</h3></Divider>
                    </div>
                    <div >
                        <Card title="Devoir de conseil" style={{ marginTop: 16 }} bordered={false}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    Notre contrat Assurance Habitation Locataire couvre votre responsabilité locative obligatoire
                                    et vos biens. Dans la mesure où votre logement n'est pas référencé aux monuments historiques,
                                    il est éligible à notre offre.
                                    Les questions suivantes permettront de recueillir vos besoins et exigences et de valider
                                    leur adéquation avec nos garanties. Pour plus d'information, n'hésitez pas à contacter
                                    un conseiller ALTIMA.
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: 16 }}>
                                {
                                    capitalReducer.capitaux !== undefined &&
                                    <Col span={23} offset={1}>
                                        <FormItem label={"Je souhaite garantir mon patrimoine mobilier pour une valeur totale maximale de "
                                            + Intl.NumberFormat().format(capitalReducer.capitaux.capitalObjetUsuel) + " €"} colon={false}>
                                            {getFieldDecorator('usualObjectsValue',
                                                {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Veuillez préciser si vous souhaitez garantir votre patrimoine mobilier pour une valeur totale maximale de '
                                                            + Intl.NumberFormat().format(capitalReducer.capitaux.capitalObjetUsuel) + ' €'
                                                    }],
                                                })(
                                                    <RadioGroup onChange={onChangeUsuable}>
                                                        <Radio value={true}>Oui</Radio>
                                                        <Radio value={false}>Non</Radio>
                                                    </RadioGroup>
                                                )}
                                        </FormItem>
                                        <FormItem label={"Je souhaite garantir mes bijoux et objets précieux pour une valeur maximale de "
                                            + Intl.NumberFormat().format(capitalReducer.capitaux.capitalObjetValeur) + " €"} colon={false}>
                                            {getFieldDecorator('isValuableObjectsValue',
                                                {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Veuillez préciser si vous souhaitez garantir vos bijoux et objets précieux pour une valeur maximale de '
                                                            + Intl.NumberFormat().format(capitalReducer.capitaux.capitalObjetValeur) + ' €'
                                                    }],
                                                })(
                                                    <RadioGroup onChange={onChangeValuable}>
                                                        <Radio value={true}>Oui</Radio>
                                                        <Radio value={false}>Non</Radio>
                                                    </RadioGroup>
                                                )}
                                        </FormItem>
                                        <FormItem label="Je souhaite garantir ma piscine, son abri, ou les terrains, cultures et plantations, ou les serres et leur contenu." colon={false}>
                                            {getFieldDecorator('isMultipleItemsValue',
                                                {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Veuillez préciser votre choix.'
                                                    }],
                                                })(
                                                    <RadioGroup onChange={onChangeMultiple}>
                                                        <Radio value={true}>Oui</Radio>
                                                        <Radio value={false}>Non</Radio>
                                                    </RadioGroup>
                                                )}
                                        </FormItem>
                                    </Col>
                                }
                            </Row>
                        </Card>
                        {errorMessage &&
                            <Row gutter={16} style={{ marginTop: 32 }}>
                                <Col span={18} offset={4}>
                                    <Alert
                                        message={errorMessage}
                                        type={messageType}
                                        showIcon
                                    />
                                </Col>
                            </Row>}
                    </div>
                    <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 32 }}>
                        <Col xs={24} lg={4} >
                            <FormItem>
                                <Link to='/resume'>
                                    <Button
                                        type="secondary"
                                        style={{ width: '100%' }}
                                    >
                                        Précédent
                                    </Button>
                                </Link>
                            </FormItem>
                        </Col>
                        <Col xs={24} lg={4} >

                            <FormItem>
                                <Link to='/renseignement-bancaires'>
                                    <Button
                                        type="primary"
                                        style={{ width: '100%' }}
                                        disabled={isDisableButton}
                                        onClick={() => captureAndNext(devisReducer.idRefPers, completeId)}
                                    >
                                        Suivant
                                    </Button>
                                </Link>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </div>
    );
};

export default Form.create()(Advice)