import instance from './services'
import { compare } from '../utils/arrayUtils'

const PATH = "bailleurs_sociaux";

function getContent() {
  const url = encodeURI("/api/v1/content/" + PATH);
  return instance.get(url)
    .then(res => res.data?.data[0])
    .catch(error => console.error(error))
}

export function getContentByUrl(url) {
  return instance.get(url)
    .then((response) => response.data)
    .catch(error => console.error(error))
}

export function getFaq() {
  return getContent().then((content) =>
    getContentByUrl(content.relationships.field_liste_qf_page_bs.links.related.href).then((response) =>
      response.data.map((rawFaq) => {
        return {
          titre: rawFaq.attributes.field_titre_qf_bs,
          description: rawFaq.attributes.field_description_qf_bs,
          rubrique: rawFaq.attributes.field_rubrique_qf_bs,
          ordre: rawFaq.attributes.field_ordre_qf_bs
        };
      }).sort((faqA, faqB) => compare(faqA.rubrique, faqB.rubrique) || compare(faqA.ordre, faqB.ordre))
    )
  )
}

export function getGaranties() {
  return getContent().then((content) =>
    getContentByUrl(content.relationships.field_liste_garanties_page_bs.links.related.href).then((response) =>
      response.data.map((rawGarantie) => {
        return {
          titre: rawGarantie.attributes.field_titre_type_d_assurance_bs,
          descriptionTypeAssurance: rawGarantie.attributes.field_desc_type_d_assurance_bs,
          descriptionLimitesGaranties: rawGarantie.attributes.field_desc_limites_garanties_bs
        };
      })
    )
  )
}

export function getHowItWork() {
  return getContent().then((content) =>
    getContentByUrl(content.relationships.field_liste_how_it_work_page_bs.links.related.href).then((response) => 
      response.data.map((rawHowItWork) => {
        return {
          titre: rawHowItWork.attributes.field_titre_how_it_work_bs,
          description: rawHowItWork.attributes.field_description_how_it_work_bs
        };
      })
    )
  )
}