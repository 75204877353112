import { Col, Icon, Row, Steps } from 'antd';
import * as React from 'react';

const Step = Steps.Step;


export default (props) => {

    return (
        <Row>
            <Col span={24} >
                <div className="view-desktop">
                    <Steps current={props.step} size={"small"}>
                        <Step title="Logement" icon={<Icon type="home" />} />
                        <Step title="Identité" icon={<Icon type="user" />} />
                        <Step title="Devis" icon={<Icon type="solution" />} />
                        <Step title="Récapitulatif" icon={<Icon type="bars" />} />
                        <Step title="Conseil" icon={<Icon type="safety" />} />
                        <Step title="Infos bancaires" icon={<Icon type="bank" />} />
                        <Step title="Paiement" icon={<Icon type="credit-card" />} />
                        <Step title="Souscription" icon={<Icon type="check-circle" />} />
                    </Steps>
                </div>
                <div className="view-mobile" style={{ display: 'none' }}>
                    <Steps current={props.step} direction="vertical" size="small">
                        <Step title="Logement" />
                        <Step title="Identité" />
                        <Step title="Devis" />
                        <Step title="Récapitulatif" />
                        <Step title="Conseil" />
                        <Step title="Infos bancaires" />
                        <Step title="Paiement" />
                        <Step title="Souscription" />
                    </Steps>
                </div>
            </Col>
        </Row>
    );
}