import instance from "./services";

export function createInvoice(codeLogement, idPerson, idDevis, infoHamon) {
  const url = encodeURI("/api/v1/payment/codeLogement/" + btoa(codeLogement) + "/person/" + idPerson + "/devis/" + idDevis);
  return instance.post(url, infoHamon)
    .then(res => {
      return res.data;
    });
}

export function getUrlPayment(codeFacture) {
  const url = encodeURI("/api/v1/payment/facture/" + codeFacture);
  return instance.get(url)
    .then(res => {
      return res.data;
    });
}

export function checkIbanToBic(iban) {
  const url = encodeURI("/payment/v1/ibantobic/" + iban);
  return instance.get(url)
    .then(res => {
      return res.data;
    });
}